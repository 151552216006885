import { useState } from "react";
export default function useSearch(data, options = { excludedColumns: [] }) {
  const [results, setResults] = useState();
  const requestSearch = (searchedVal) => {
    const lowercasedValue = searchedVal.toLowerCase().trim();
    if (lowercasedValue === "") setResults(data);
    else {
      const filteredRows = data.filter((item) => {
        return Object.keys(item).some((key) =>
          options.excludedColumns.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });

      setResults(filteredRows);
    }
  };
  const cancelSearch = () => {
    setResults(data);
  };

  return {
    searchResults: results ?? data,
    setSearchResults: setResults,
    requestSearch,
    cancelSearch,
  };
}
