import { PropTypes } from "prop-types";
import { useRef, useState } from "react";
import Button from "../button/Button";
import "./TextInput.css";

export default function TextInput(props) {
  const [isEditing, setIsEditing] = useState(false);
  const onClickSubmit = async (e) => {
    await props.onClickSubmit(props.value);
    setIsEditing(false);
  };

  const onClickCancel = (e) => {
    if (isEditing) {
      setIsEditing(false);
    }
  };
  const renderButton = () => {
    return (
      <div className="btn-group-horiz" onMouseDown={(e) => e.preventDefault()}>
        <Button
          variant="default fill long "
          onClick={onClickSubmit}
          loading={props.submitLoading}
          tabIndex={2}
        >
          Submit
        </Button>
        <Button
          variant="negative fill short"
          onClick={onClickCancel}
          loading={props.submitLoading}
          tabIndex={2}
        >
          Cancel
        </Button>
      </div>
    );
  };

  const textBox = useRef();
  const onClick = (e) => {
    setIsEditing(true);
  };
  return (
    <div
      className={`t-text-input ${isEditing ? "expand" : "collapse"}`}
      tabIndex={0}
    >
      <div className="t-label">{props.name}</div>
      {isEditing ? (
        <textarea
          tabIndex={1}
          onChange={props.onChange}
          className="text-display"
          ref={textBox}
          autoFocus
          value={props.value ?? ""}
        />
      ) : (
        <div className="text-display">
          <span className="text">{props.defaultValue} </span>
          <button className="button_text t-title xxs" onClick={onClick}>
            Edit
          </button>
        </div>
      )}
      {isEditing ? <>{renderButton()}</> : <></>}
    </div>
  );
}

TextInput.propTypes = {
  onSubmit: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
};
