import BookingsViewContainer from "../trip-detail/trip-detail-bookings/bookings-view/BookingsViewContainer"
import TasksView from "./trip-detail-tasks/tasks-view/TasksView"
import SidebarWithPage from "../composable/sidebar-with-page/SidebarWithPage"
import TripDetailContent from "./sidebar-trip-detail/TripDetailContent"
import { useState, useContext } from "react"
import "./TripDetailView.css"
import { TripSelectionContext } from "../../context/TripSelectionContext"
import useTripById from "../../hooks/useTripById"
import { useEditTripRemarks } from "hooks/useEditRemarks"
import { BookingSelectionContext } from "../../context/BookingSelectionContext"
import { ViewsContext } from "../../context/ViewsContext"
export default function TripDetailView({ openSnackbar, ...props }) {
  const { selectedTripId } = useContext(TripSelectionContext)
  const { clearSelectedBookings } = useContext(BookingSelectionContext)
  const { isFetching: detailIsLoading, data: tripDetailData } =
    useTripById(selectedTripId)

  let [detailView, setDetailView] = useState("bookings")
  const {
    openView,
    isVisibleSidebar,
    isVisiblePage,
    setShowPage,
    closeCurrentView,
  } = useContext(ViewsContext)
  const onClickNewTrip = (e) => {
    e.stopPropagation()
    openView({ viewName: "new_trip", showPage: true, showSidebar: true })
  }
  const onClickPassengers = (e) => {
    clearSelectedBookings()
    e.stopPropagation()
    setDetailView("bookings")
    setShowPage(true)
  }
  const onClickTasks = () => {
    clearSelectedBookings()
    setDetailView("tasks")
    setShowPage(true)
  }

  const onClickBooking = (e) => {
    e.stopPropagation()
    openView({
      viewName: "booking-detail",
      showPage: false,
      showSidebar: true,
    })
  }
  const onClickBack = (e) => {
    e.stopPropagation()
    clearSelectedBookings()
    setShowPage(false)
  }

  const onClickReassign = (e) => {
    e.stopPropagation()
    openView({ viewName: "booking-detail", showPage: true, showSidebar: true })
  }

  const { updateRemarks } = useEditTripRemarks()
  const onSubmitRemarks = (remarks) => {
    try {
      updateRemarks({ remarks, tripId: selectedTripId })
      openSnackbar({ message: "done", severity: "success" })
    } catch (e) {
      openSnackbar({ message: "error submitting remarks", severity: "error" })
    }
  }

  function renderBookingList() {
    if (detailView === "bookings") {
      return (
        <BookingsViewContainer
          onClickBack={onClickBack}
          onClickClose={closeCurrentView}
          onClickBooking={onClickBooking}
          openReassignView={onClickReassign}
        />
      )
    } else if (detailView === "tasks") {
      return (
        <TasksView
          onClickBack={onClickBack}
          onClickClose={closeCurrentView}
          onClickBooking={onClickBooking}
          openReassignView={onClickReassign}
          openNewTrip={onClickNewTrip}
        />
      )
    } else {
      return <div></div>
    }
  }
  return (
    <>
      <SidebarWithPage
        showSidebar={isVisibleSidebar("trip-detail") && !!selectedTripId}
        showPage={isVisiblePage("trip-detail") && !!selectedTripId}
        loading={detailIsLoading}
        sidebarIsLoading={detailIsLoading}
        onClickPage={
          isVisibleSidebar("booking-detail") ? closeCurrentView : () => {}
        }
        sidebarContent={
          <>
            {!detailIsLoading ? (
              <TripDetailContent
                {...tripDetailData}
                onClickPassengers={onClickPassengers}
                onClickTasks={onClickTasks}
                onClickSubmit={onSubmitRemarks}
              />
            ) : (
              <></>
            )}
          </>
        }
        pageContent={
          <div className="trip-detail-page">{renderBookingList()}</div>
        }
      />
    </>
  )
}
