import { useQuery } from "react-query"
import { useHeaderFilters, makeDateForAPI } from "./useHeaderFilters"
import { getBookingsViewDataForUi } from "../util/bookings"
import { useApi } from "./api"
export function useBookings({ tripId } = {}) {
  const {
    selectedDestination,
    selectedDate,
    selectedDirectionIsArrival,
    selectedDirection,
  } = useHeaderFilters()
  const { get } = useApi()
  const getBookings = async () => {
    const data = await get("bookings", {
      arrival: selectedDirectionIsArrival,
      destination: selectedDestination,
      flight__date: makeDateForAPI(selectedDate),
      trip: tripId,
    })
    return getBookingsViewDataForUi(data)
  }
  const query = useQuery(
    [
      "bookings",
      {
        selectedDestination,
        selectedDate,
        selectedDirection,
        tripId,
      },
    ],
    getBookings,
    {
      placeholderData: [],
      staleTime: 5000,
      keepPreviousData: true,
      retry: 2,
    }
  )
  return {
    ...query,
    showLoading: query.isLoading || query.isFetching,
  }
}
export default useBookings
