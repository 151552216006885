import { useQuery } from "react-query"
import { useApi } from "./api"
import {getDestinationsViewDataForUi} from "../util/destinations";
export function useDestinations({ id } = {}) {// query database and return the data
  const { get } = useApi()
  const getDestinations = async () => {
    // http and https compatibility
    const data = await get("destinations")
    return getDestinationsViewDataForUi(data)
  }
  const query = useQuery(
    [
      "destinations-including-disabled",
    ],
    getDestinations,
    {
      placeholderData: [],
      staleTime: 5000,
      keepPreviousData: true,
      retry: 2,
    }
  )
  return {
    ...query,
    showLoading: query.isLoading || query.isFetching,
  }
}
export default useDestinations
