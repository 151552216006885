import {Select,MenuItem,InputLabel,FormControl,Box} from '@material-ui/core';
import { TripsView } from "./trips-view/TripsView";
import { useState, useContext } from "react";
import NewTripContainer from "./new-trip/NewTripContainer";
import { BookingsView } from "./bookings-view/BookingsView";
import { RQjobsView } from "./rqjobs-view/RQjobsView";
import BookingDetailContainer from "./booking-detail/BookingDetailContainer";
import TopNav from "components/navigation/TopNav";
import NavMenu from "components/nav-menu/NavMenu";
import {
  NavLink,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import MenuButton from "components/nav-menu/MenuButton";

import TripDetailView from "./trip-detail/TripDetailView";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { BookingSelectionProvider } from "../context/BookingSelectionContext";
import { TripSelectionProvider } from "../context/TripSelectionContext";
import HeaderNotifButton from "./header/HeaderNotifButton";
import useNotifications from "../hooks/useNotifications";
import { ViewsContext, ViewsProvider } from "../context/ViewsContext";
import { SnackbarContext, SnackbarProvider } from "../context/SnackbarContext";
import { RQjobSelectionProvider } from "context/RQjobSelectionContext";
import {DestinationSelectionProvider} from "../context/DestinationSelectionContext";
import {DestinationsView} from "./destinations-view/DestinationsView";
import {InsightSelectionProvider} from "../context/InsightSelectionContext";
import {InsightsView} from "./insights-view/InsightsView";
import Button from "components/button/Button";
import { useApi } from "hooks/api";
import Loader from "react-loader-spinner";
import { useTrips } from "../hooks/useTrips";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Views() {
  const {
    openSnackbar,
    snackbar: { open, message, severity },
    closeSnackbar,
  } = useContext(SnackbarContext);

  const [showNotif, setShowNotif] = useState(false);
  const toggleShowNotif = () => setShowNotif(!showNotif);
  const { refetch } = useTrips();

  const { closeAllViews } = useContext(ViewsContext);
  const [showMenu, setShowMenu] = useState(false);
  const onNavigate = () => {
    setShowNotif(false);
    setShowMenu(false);
    closeAllViews();
  };

  const {get, post} = useApi()
  const [loading, setLoading] = useState(false);
  const [maxStops, setMaxStops] = useState(8);

  const handleChange = async(event) => {
    setLoading(true);
    setMaxStops(event.target.value);
    try {
      await post('cyberlogic_demo_change_max_stops',
        {},
        {
          "max_stops": event.target.value
        }
    )
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    
  };

  const handleApiCall = async () => {
    setLoading(true);
    setShowMenu(!showMenu)
    try {
      await get('init_cyberlogic_demo_data');
      await post('instances',{
        "destination": 290,
        "date": "2021-09-25",
        "cost": 0.0,
        "number_trips": 0,
        "pax_sum": 0
      })
      await refetch(); // reload the table
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderTripsView = () => {
    return (
      <TripSelectionProvider>
        <div className="trips-view">
          <BookingSelectionProvider>
            <TripDetailView openSnackbar={openSnackbar} />
            <BookingDetailContainer openSnackbar={openSnackbar} />
          </BookingSelectionProvider>
          <TripsView showNotif={showNotif} onNavigate={onNavigate} />
        </div>
      </TripSelectionProvider>
    );
  };

  const renderBookingsView = () => {
    return (
        <BookingSelectionProvider>
          <BookingDetailContainer openSnackbar={openSnackbar} />
          <BookingsView showNotif={showNotif} onNavigate={onNavigate} />
        </BookingSelectionProvider>
    );
  };

  const renderRQjobsView = () => {
    return (
      <RQjobSelectionProvider>
        {/* For now we don't have a detail page for rqjob */}
        <RQjobsView showNotif={showNotif} onNavigate={onNavigate} />
      </RQjobSelectionProvider>
    )
  }

  const renderDestinationsView = () => {
    return (
      <DestinationSelectionProvider>
        {/* For now we don't have a detail page for rqjob */}
        <DestinationsView showNotif={showNotif} onNavigate={onNavigate} />
      </DestinationSelectionProvider>
   )
  }


  const renderInsightsView = () => {
    return (
        <InsightSelectionProvider>
          {/* For now we don't have a detail page for rqjob */}
          <InsightsView showNotif={showNotif} onNavigate={onNavigate} />
        </InsightSelectionProvider>
    )
  }


  const { notifIconTrips, notifIconBookings } = useNotifications();
  const { pathname } = useLocation();
  const icon =
    pathname === "/trips"
      ? notifIconTrips
      : pathname === "/bookings"
      ? notifIconBookings
      : {};
  const { hasNotifs, count, color } = icon;
  return (
    <>
      <TopNav searchResults={[]}>
        <HeaderNotifButton
          onClick={toggleShowNotif}
          showBadge={hasNotifs}
          badgeCount={count}
          color={color}
        />
      </TopNav>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={1500}
        open={open}
        onClose={closeSnackbar}
      >
        <Alert severity={severity} show={true}>
          {message}
        </Alert>
      </Snackbar>
      <NewTripContainer openSnackbar={openSnackbar} />
      <div className={`menu-app-container ${showMenu ? "activemenu" : ""}`}>
        <NavMenu showMenu={showMenu}>
          <NavLink
            to="/trips"
            className="menu-link t-label"
            activeClassName="selected"
            onClick={onNavigate}
          >
            Trips
          </NavLink>
          <NavLink
            to="/bookings"
            className="menu-link t-label"
            activeClassName="selected"
            onClick={onNavigate}
          >
            Bookings
          </NavLink>
          <NavLink
            to="/planningjobs"
            className = "menu-link t-label"
            activeClassName="selected"
            onClick={onNavigate}
          >
            Planning Jobs
          </NavLink>
          <NavLink
              to="/destinations"
              className = "menu-link t-label"
              activeClassName="selected"
              onClick={onNavigate}
          >
            Destinations
          </NavLink>
          
          <NavLink
              to="/insights"
              className = "menu-link t-label"
              activeClassName="selected"
              onClick={onNavigate}
          >
            Insights
          </NavLink>
          <div
              className="menu-link t-label"
              activeClassName="selected"
              onClick={handleApiCall}
          >
            Initialize Planning
          </div>

          <Box sx={{ minWidth: 120, marginTop: '16px',marginRight: '16px' }}>
            <FormControl fullWidth variant='outlined' size='small' >
              <InputLabel id="max-stops-label">Max stops</InputLabel>
              <Select

                labelId="max-stops-label"
                id="max-stops"
                value={maxStops}
                label="Max stops"
                onChange={handleChange}
              >
                <MenuItem value={8}>Eight</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
              </Select>
            </FormControl>
          </Box>

        </NavMenu>
        <div className="menu-wrapper">
          <MenuButton onClick={() => setShowMenu(!showMenu)} />
        </div>
        <div className="app-content">
          <Switch>
            <Redirect exact from="/" to="/trips" />
            <Route path="/trips" render={renderTripsView} />
            <Route path="/bookings" render={renderBookingsView} />
            <Route path="/planningjobs" render={renderRQjobsView} />
            <Route path="/destinations" render={renderDestinationsView} />
            <Route path="/insights" render={renderInsightsView} />
          </Switch>
        </div>
          {/* Loader for initialize demo button */}
         {loading && (
          <div className="backdrop">
            <div className="loading-spinner">
              <Loader type="ThreeDots" color="#0265E8" height={100} width={100} />
            </div>
          </div>
           )} 
      </div>
    </>
  );
}
const withContext = (props) => (
  <ViewsProvider>
    <SnackbarProvider>
      <Views {...props} />
    </SnackbarProvider>
  </ViewsProvider>
);
export { withContext as Views };
