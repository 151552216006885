import { memo } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { tableColumnShape, tableRowShape } from "../../util/types";
import "./Table.css";
import { PropTypes } from "prop-types";
import Loading from "../loading/Loading";
import { makeStyles } from "@material-ui/core/styles";

const NoRowsOverlay = () => (
  <div className="t-table_no-bookings">
    <span>No items to show</span>
  </div>
);
const LoadingOverlay = () => (
  <div className="t-table_overlay-loading">
    <Loading show={true} size={28} thickness={4.5} />
  </div>
);
const useStyles = makeStyles({
  root: {
    fontFamily: "tuibold",
    border: "none",
    lineHeight: "16px",
    "& .MuiDataGrid-window": {
      backgroundColor: "#fff",
      boxShadow: "0px 0px 33px rgba(17, 43, 91, 0.08)",
      borderRadius: "5px",
      "& .Mui-selected:hover": {
        backgroundColor: "var(--light-blue)",
      },

      "& .MuiDataGrid-row": {
        borderTop: "1px solid #f1f1f1",
        "& .MuiDataGrid-cell": {
          padding: "0 8px",
          fontSize: "13px",
          overflow: "hidden",
          bottom: "auto",
          top: "auto",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          "& .t-badge-container,.icon": {
            alignItems: "flex-start",
            height: "min-content",
            width: "34px",
            paddingLeft: "0px",
            marginRight: "8px",
            display: "flex",
            "& img": {
              width: "30px",
              height: "30px",
              borderWidth: "1px",
            },
          },
          "& .info": {
            flexGrow: "1",
            lineHeight: "16px",
          },
        },
        "& .t-table-cell_flex": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
      },
      "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: "var(--light-blue)",
      },
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      fontFamily: "tuibold",
      fontSize: "11px",
      color: "var(--mid-cool-gray)",
      "& .MuiDataGrid-columnSeparator": {
        color: "transparent",
      },
      "& .MuiDataGrid-columnHeader.t-table-header_icon": {
        "& .icon": {
          height: "30px",
          "& img": {
            width: "30px",
            height: "30px",
          },
        },
      },
      "& .MuiDataGrid-columnHeader.t-table-header_padded": {
        paddingLeft: "24px",
      },
      "& .MuiDataGrid-columnHeader": {
        overflow: "visible",
        padding: "0 4px",
        flexDirection: "row",
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: "0 4px",
          "& .MuiDataGrid-columnHeaderTitle": {
            overflow: "visible",
          },
        },
      },
    },
    "& .MuiCheckbox-root": {
      color: "var(--mid-gray)",
    },
    "& .Mui-checked": { color: "var(--blue)" },
  },
});
const Table = memo((props) => {
  const onSelectionModelChange = (params) => {
    const { selectionModel: arrayOfSelectedIds } = params;
    if (props.onSelectionChange) {
      props.onSelectionChange(arrayOfSelectedIds);
    }
  };
  const onClickRow = (row, evt) => {
    const { id } = row;
    if (props.onDetailClick) props.onDetailClick(evt, id);
  };

  let columns = props.columns ?? [];
  if (props.showActions) {
    columns = [
      ...columns,
      {
        field: "actions",
        headerName: " ",
        renderCell: (params) => params.value,
      },
    ];
  }
  const classes = useStyles();
  return (
    <div
      className={`t-table_wrapper ${props.className ?? ""}`}
      style={{ width: props.width ?? "100%" }}
    >
      
      <DataGrid
        ref={props.ref}
        rows={props.rows ?? []}
        columns={columns}
        rowHeight={72}
        autoHeight={true}
        className={classes.root}
        hideFooterSelectedRowCount={true}
        components={{ NoRowsOverlay, LoadingOverlay }}
        pageSize={20}
        hideFooter={false}
        disableExtendRowFullWidth={false}
        disableSelectionOnClick={true}
        checkboxSelection={props.enableSelect}
        loading={props.loading}
        disableColumnMenu={true}
        onRowClick={onClickRow}
        sortingOrder={["asc", "desc"]}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={props.selectionModel}
        state={{
          keyboard: {
            cell: null,
            columnHeader: null,
            isMultipleKeyPressed: false,
          },
        }}
        {...props.tableConfig}
      />
    </div>
  );
});

Table.propTypes = {
  columns: PropTypes.arrayOf(tableColumnShape),
  rows: PropTypes.arrayOf(tableRowShape),
  loading: PropTypes.bool,
  onDetailClick: PropTypes.func,
  onEditClick: PropTypes.func,
  selectionModel: PropTypes.array,
  onSelectionChange: PropTypes.func,
  enableSelect: PropTypes.bool,
  showActions: PropTypes.bool,
  fieldPresets: PropTypes.object,
};
export default Table;
