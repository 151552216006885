import { PropTypes } from "prop-types";
import { IconWrapper } from "components/icons/Icon";
import "./TaskCard.css";
import { IconMarker } from "components/icons/IconMarker";
export default function TaskCard(props) {
  return (
    <div className="task-card_header t-box-shadow">
      <div className="item outer">
        {props.icon}
        <div className="item">
          <div className="t-label">{props.locationType === 'airport' ? 'Airport' : 'Area'}</div>
          <div className="t-title xs">{
            // Hack--this is not necessarily the airport name
            props.locationType === 'airport' ? props.location : props.title
          }
          </div>
        </div>
      </div>
      <div className="item outer">
        <IconWrapper>
          <IconMarker />
        </IconWrapper>
        <div className="item task-card_location">
          <div className="t-label">Location</div>
          <div className="t-title xs">{props.location}</div>
        </div>
      </div>
      <div className="item outer">
        <div className="task-card_details_container">
          <div className="t-label">Details</div>
          <div>
            <div className="t-title xs">{props.taskLabel}</div>
            <div className="t-copy lg task-card_pax-label">
              {props.paxLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TaskCard.propTypes = {
  icon: PropTypes.any,
  location: PropTypes.any,
  paxLabel: PropTypes.any,
  taskLabel: PropTypes.any,
  title: PropTypes.any,
};
