import StatusSelector from "components/status-selector/StatusSelector";
import useHeaderFilters from "hooks/useHeaderFilters";

export function HeaderStatusPicker(props) {
  const {selectedStatus, onStatusChange, availableStatus} = useHeaderFilters();
  return(
    < StatusSelector
      value = {selectedStatus}
      options = {availableStatus}
      onChange= {onStatusChange}
    />
  );
}

export default HeaderStatusPicker;