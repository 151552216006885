import PropTypes from 'prop-types'
/** object shapes for use with React proptypes */
export const listItemShape = {
    label: PropTypes.string,
    title: PropTypes.string,
    statusText: PropTypes.string,
    showStatus: PropTypes.bool,
    id: PropTypes.string,
}

export const dropdownOptionShape = {
    key: PropTypes.string,
    value: PropTypes.any, 
    label: PropTypes.string
}

export const dropdownShape = {
    label:PropTypes.string.isRequired,
    category: PropTypes.string,
    options:PropTypes.arrayOf(PropTypes.shape(dropdownOptionShape)).isRequired,
    key:PropTypes.string
}

export const tableColumnShape = PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string,
    width: PropTypes.number
})

export const tableRowShape = PropTypes.objectOf(PropTypes.any)
 