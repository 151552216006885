import { useQuery } from "react-query"
import { parseTripDetail, emptyTripDetail } from "../util/trips"
import { useApi } from "./api"
export default function useTripById(tripId) {
  const { get } = useApi()
  const getTrip = async (tripId) => {
    // const data = await get("trip_detail/" + tripId);
    let data
    try {
      // data = await get("trip_detail/" + "abcd")
      data = await get("trip_detail/" + tripId)
    } catch (e) {
      throw e
    }

    return parseTripDetail(data)
  }

  const query = useQuery(["trip", tripId], () => getTrip(tripId), {
    placeholderData: emptyTripDetail,
    keepPreviousData: true,
    retry: false,
    enabled: !!tripId,
    staleTime: 10000,
  })

  return {
    ...query,
    getTrip,
    showLoading: query.isLoading || query.isFetching,
  }
}
