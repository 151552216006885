import { PropTypes } from "prop-types";
import "./Button.css";
import { CircularProgress } from "@material-ui/core";
/*
variants:
    color
    - light (gray)
    - default (blue)
    - negative
    style
    - fill
    - outline
*/

Button.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
export default function Button(props) {
  const variant = props.variant || "default fill";
  const loading = props.loading ?? false;
  return (
    <button
      ref={props.ref}
      onClick={props.onClick}
      className={`mobi-tui-button ${variant} ${props.className}`}
      aria-label={props.ariaLabel}
      disabled = {props.disabled}
    >
      {loading ? (
        <CircularProgress color="inherit" size={25} />
      ) : (
        props.children ?? props.text
      )}
    </button>
  );
}
