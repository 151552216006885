import "./NavMenu.css";
import { PropTypes } from "prop-types";
NavMenu.propTypes = {
  showMenu: PropTypes.bool,
};
export default function NavMenu(props) {
  return (
    <div className={"nav-menu " + (props.showMenu ? "show" : "hide")}>
      {props.children}
    </div>
  );
}
