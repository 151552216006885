import { useMutation, useQueryClient } from "react-query"
import { useApi } from "./api"
import { useHeaderFilters, makeDateForAPI } from "./useHeaderFilters"
export default function useInsertBookings() {
  const { post } = useApi()
  const postInsert = async ({ destination, arrival, operation_date, bookingIds }) => {
    await post(`bookings/insertion_replan?arrival=${arrival}&destination=${destination}&force=false&operation_date=${makeDateForAPI(operation_date)}`, {
      destination: JSON.stringify(destination),
      arrival: arrival,
      operation_date: JSON.stringify(operation_date),
      id__in: JSON.stringify(bookingIds),
    })
    return { bookingIds }
  }
  const { selectedDestination, selectedDate, selectedDirection } = useHeaderFilters()
  const onInsertDone = ({ bookingIds }) => {
    for (const booking of bookingIds) {
      queryClient.setQueryData(["booking", booking], (oldData) => ({
        ...oldData,
      }))
    }
    queryClient.refetchQueries([
      "trips",
      { selectedDestination, selectedDate, selectedDirection },
    ])
    queryClient.refetchQueries([
      "bookings",
      { selectedDestination, selectedDate, selectedDirection },
    ])
  }
  const queryClient = useQueryClient()
  const {
    mutateAsync: insertBookings,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(postInsert, {
    mutationKey: "insertBookings",
    onSuccess: onInsertDone,
  })
  return { insertBookings, isError, isLoading, isSuccess }
}
