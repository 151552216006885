import PageHeader from "components/page-header/PageHeader";
import PropTypes from "prop-types";
import HeaderButton from "components/page-header/HeaderButton";
import { useHeaderFilters } from "hooks/useHeaderFilters";
import { useContext, useEffect } from "react";
import ReassignMultipleBookingsButton from "views/composable/buttons/ReassignMultipleBookingsButton";
import InsertMultipleBookingsButton from "views/composable/buttons/InsertMultipleBookingsButton";
import { BookingSelectionContext } from "../../context/BookingSelectionContext";
import HeaderDirectionToggle from "views/header/HeaderDirectionToggle";
import Notification from "views/header/Notification";
import useNotifications from "hooks/useNotifications";
import { ViewsContext, ViewNames } from "../../context/ViewsContext";
import useBookings from "hooks/useBookings";
import { bookingsFilterFields, tableColumns } from "../../util/bookings";
import {
  TableContextProvider,
  TableContextConsumer,
  TableContext,
} from "context/TableContext";
import FilterBar from "components/filter-bar/FilterBar";
import Table from "components/table/Table";
import { HeaderDatePicker } from "views/header/HeaderDatePicker";
import { HeaderLocationPicker } from "views/header/HeaderLocationPicker";
import { SnackbarContext } from "context/SnackbarContext"
import useInsertBookings from "hooks/useInsertBookings";


const BookingsView = (props) => {
  const {
    setSelectedBookings,
    numBookingsSelected,
    selectedBookingIds,
  } = useContext(BookingSelectionContext);
  const { openView, closeAllViews } = useContext(ViewsContext);
  const { showLoading } = useBookings();
  const onClickRow = (event, id) => {
    event.stopPropagation();
    setSelectedBookings([id]);
    props.onNavigate();
    openView({
      viewName: "booking-detail",
      showSidebar: true,
      showPage: false,
    });
  };
  const { openSnackbar } = useContext(SnackbarContext)
  const onClickReassign = (e, count) => {
    e.stopPropagation();
    if (count === 0) {
      openSnackbar({ message: "Please select at least one booking", severity: "error"})
    } else {
      openView({ viewName: "booking-detail", showSidebar: true, showPage: true });
    }
  };

  const onClickInsert = async (e, count) => {
    e.stopPropagation();
    if (count === 0) {
      openSnackbar({ message: "Please select at least one booking", severity: "error"})
    } else {
      await insertBookings({ bookingIds: selectedBookingIds, destination: selectedDestination, operation_date: selectedDate, arrival: selectedDirectionIsArrival })
      openSnackbar({ message: "success", severity: "success" })
    }
  };

  const onClickNewTrip = (e) => {
    e.stopPropagation();
    openView({
      viewName: ViewNames.newTrip,
      showPage: true,
      showSidebar: true,
    });
  };

  const { selectedDestination, selectedDate, selectedDirection, selectedDirectionIsArrival } =
    useHeaderFilters();
  const { currentFilters, onFilterChange: setFilters } =
    useContext(TableContext);
  useEffect(
    () => setFilters({}),
    [selectedDestination, selectedDate, selectedDirection]
  );
  const { unassignedBookingsMessage } = useNotifications();
  const viewUnassignedBookings = () => {
    closeAllViews();
    setFilters({
      ...currentFilters,
      assignedTripSign: ["Unassigned"],
    });
  };
  const { insertBookings } = useInsertBookings()

  return (
    <div>
      <Notification
        open={props.showNotif}
        message={unassignedBookingsMessage}
        onClickAction={viewUnassignedBookings}
      />
      <div className="trips-list">
        <div className="mobi-tui-pagewrap" onClick={closeAllViews}>
          <PageHeader title="All Bookings" subtitle="Bookings">
            <HeaderDirectionToggle />
            <HeaderLocationPicker />
            <HeaderDatePicker />
            <ReassignMultipleBookingsButton
                numBookingsSelected={numBookingsSelected}
                onClickReassign={(e) => onClickReassign(e, numBookingsSelected)}
              />
            <InsertMultipleBookingsButton
                numBookingsSelected={numBookingsSelected}
                onClickInsert={(e) => onClickInsert(e, numBookingsSelected)}
              />
            <HeaderButton text="New Trip" onClick={onClickNewTrip} />
          </PageHeader>

          <TableContextConsumer>
            <FilterBar withSearch={true} />
            <Table
              columns={tableColumns}
              showActions={false}
              enableSelect={true}
              onDetailClick={onClickRow}
              onSelectionChange={setSelectedBookings}
              loading={showLoading}
              selectionModel={selectedBookingIds}
            />
          </TableContextConsumer>
        </div>
      </div>
    </div>
  );
};

BookingsView.propTypes = {
  showNotif: PropTypes.bool,
};
const BookingsViewWrapped = (props) => {
  const { data } = useBookings();
  return (
    <TableContextProvider data={data} filterFields={bookingsFilterFields}>
      <BookingsView {...props} />
    </TableContextProvider>
  );
};

export { BookingsViewWrapped as BookingsView };
