import { makeDateForAPI, useHeaderFilters } from "./useHeaderFilters"
import { useMutation, useQueryClient } from "react-query"

import { useApi } from "./api"
export default function usePlanTrips() {
  const {
    selectedDestination,
    selectedDate,
    selectedDirection,
  } = useHeaderFilters()
  const { get } = useApi()
  const getPlanTrips = async ({force}) => {
    const data = await get("instances/trigger", {
      destination: selectedDestination,
      date: makeDateForAPI(selectedDate),
      force: true,
      dashboard: true,
    })
    return { data }
  }
  const onPlanTriggerDone = () => {
    queryClient.refetchQueries([
      "trips",
      { selectedDestination, selectedDate, selectedDirection },
    ])
    queryClient.refetchQueries([
      "bookings",
      { selectedDestination, selectedDate, selectedDirection },
    ])
  }
  const queryClient = useQueryClient()
  const {
    mutateAsync: planTrips,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(getPlanTrips, {
    mutationKey: "planTrips",
    onSuccess: onPlanTriggerDone,
  })
  return { planTrips, isError, isLoading, isSuccess }
}
