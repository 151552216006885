import Back from "components/actions/Back"
import ReassignMultipleBookingsButton from "views/composable/buttons/ReassignMultipleBookingsButton"
import HeaderButton from "components/page-header/HeaderButton"
import PageHeader from "components/page-header/PageHeader"
import { PropTypes } from "prop-types"
import "./BookingsView.css"
import { TableContextConsumer } from "context/TableContext"
import FilterBar from "components/filter-bar/FilterBar"
import Table from "components/table/Table"
import { useContext } from "react"
import { BookingSelectionContext } from "context/BookingSelectionContext"
import StatusLabel from "components/card/card-content/StatusLabel"
import { BookingIcon } from "components/icons/Icon"
import { ViewsContext } from "context/ViewsContext"

TripDetailBookings.propTypes = {
  onClickBack: PropTypes.func,
  bookingsListItems: PropTypes.array,
  onClickClose: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickRemove: PropTypes.func,
}
const tableColumns = [
  {
    field: "booking",
    headerName: "Lead Name",
    flex: 8,
    renderCell: (params) => (
      <>
        {params.value.icon ?? <BookingIcon />}
        <div className="info">
          <div className="t-label">{params.value.label} PAX</div>
          <div>{params.value.title}</div>
        </div>
      </>
    ),
    sortComparator: (value1, value2) =>
      value1.title.localeCompare(value2.title),
  },
  { field: "id", headerName: "ID ", flex: 15},
  { field: "extBooking", headerName: "Booking", flex: 4 },
  { field: "area", headerName: "Area", flex: 4 },
  { field: "hotel", headerName: "Hotel", flex: 4 },
  { field: "flightNumber", headerName: "Flight (IATA)", flex: 4 },
  {
    field: "flightBookingTime",
    headerName: "Time",
    flex: 2,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => (
      <div>{params.value ? <StatusLabel text={params.value} /> : <></>}</div>
    ),
    flex: 4,
  },
]
export default function TripDetailBookings(props) {
  const {
    selectedBookingIds,
    setSelectedBookings,
    areAnyBookingsSelected,
    numBookingsSelected,
    selectBooking,
  } = useContext(BookingSelectionContext)
  const { openView } = useContext(ViewsContext)
  const onClickReassign = (event) => {
    event.stopPropagation()
    props.openReassignView(event)
  }
  const handleClickBooking = (e, id) => {
    e.stopPropagation()
    selectBooking(id)
    openView({
      viewName: "booking-detail",
      showPage: false,
      showSidebar: true,
    })
  }
  return (
    <div>
      <Back onClick={props.onClickBack} />
      <PageHeader title="Bookings">
        <HeaderButton
          onClick={props.onClickClose}
          variant="light fill"
          text="close"
        />
        {areAnyBookingsSelected ? (
          <ReassignMultipleBookingsButton
            numBookingsSelected={numBookingsSelected}
            onClickReassign={onClickReassign}
          />
        ) : (
          <HeaderButton text="Add Bookings" onClick={props.onClickAdd} />
        )}
      </PageHeader>
      <TableContextConsumer>
        <FilterBar withSearch={false} />
        <Table
          columns={tableColumns}
          showActions={false}
          selectionModel={selectedBookingIds}
          onSelectionChange={setSelectedBookings}
          enableSelect={true}
          onDetailClick={handleClickBooking}
          loading={props.loading}
        />
      </TableContextConsumer>
    </div>
  )
}
