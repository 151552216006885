import { useState } from "react";
import { filterItemsFromData, filterData } from "../util/filtering";
/**
 *
 * @param {f} data
 * @param {*} filterFields : [{field:String, label:String},...]
 * @param {*} initialFilters: {field:String => value:any}
 */
export default function useFilters(
  data = [],
  filterFields = [],
  initialFilters = {}
) {
  const [filters, setFilters] = useState(initialFilters);
  const filterBarItems = filterItemsFromData(filterFields, data);
  const filteredData = filterData(data, filters);
  const numFilterResults = filteredData.length;
  return {
    setFilters,
    filters,
    filteredData,
    filterBarItems,
    numFilterResults,
  };
}
