export const IconMarker = () => {
  return (
    <svg
      className="icon-marker"
      width="1em"
      height="1.5em"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9176 8.73326C13.0393 8.10029 13.0393 7.44392 13.0393 6.49146C13.0393 2.90632 10.133 0 6.54785 0C2.96272 0 0.0563965 2.90632 0.0563965 6.49146C0.0563965 7.45176 0.0563965 8.09168 0.18115 8.70627C0.522142 10.3862 1.79518 11.8769 6.54785 19.2039C11.3147 12.0536 12.5811 10.4828 12.9176 8.73326ZM6.5479 9.19635C8.0417 9.19635 9.25267 7.98538 9.25267 6.49158C9.25267 4.99777 8.0417 3.7868 6.5479 3.7868C5.05409 3.7868 3.84313 4.99777 3.84313 6.49158C3.84313 7.98538 5.05409 9.19635 6.5479 9.19635Z"
        fill="currentColor"
      />
    </svg>
  );
};
