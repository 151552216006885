import { useState, useCallback } from "react";
import Button from "components/button/Button";
import { useApi } from "hooks/api";
import RefreshButton from "components/button/RefreshButton";

export function getDestinationsViewDataForUi(dataFromApi = []) {
  function toDestinationsListItem(item = {}) {
    return {
      // key: item.id,
      id: item.id,
      name: item.name,
      allowed: item.allowed,
    };
  }
  return dataFromApi.map(toDestinationsListItem);
}
export function getDestinationDetailDataForUi(dataFromApi) {
  const {
    id,
    name,
    allowed,
  } = dataFromApi;
  const dataForUi = {
    id,
    name,
    allowed,
  };
  return dataForUi;
}

export const emptyDestinationDetail = {
  id: "",
  name: "",
  allowed: "",
  refresh: "",
};


const EnableDestinationButton = (props) => {
  const [isSending, setIsSending] = useState(false)
  const {get} = useApi()
  const sendRequest = useCallback(async () => {
    if(!window.confirm(`Are you sure you want to enable destination ${props.id}?`)) return

    // don't send again while we are sending
    if (isSending || props.disabled) return
    // update state
    setIsSending(true)
    // send the actual request
    const res = await get(`destinations/${props.id}/enable_destination`, {})
    // once the request is sent, update state again
    console.log(res) // the reload is complete
    setIsSending(false)
    // TBD - how to better refresh the data - this reloads whole page which might be ok for this...
    window.location.reload();
  }, [isSending]) // update the callback if the state changes
  return <Button disabled={props.disabled} loading={isSending} onClick={sendRequest}>Enable {props.id}</Button>

};


export const tableColumns = [
  {
    field: "id",
    headerName: "ID",
    flex: 10,
  },
  {
    field: "name",
    headerName: "NAME",
    flex: 10,
  },
  {
    field: "allowed",
    headerName: "ENABLED",
    flex: 10,
  },
  {
    field: "enable",
    headerName: "ENABLE",
    flex: 5,
    renderCell: (props) => (
        <EnableDestinationButton id = {props.id} disabled = {props.row["allowed"]}/>
    ),
  },
  {
    field: "refresh",
    headerName: "REFRESH",
    flex: 5,
    renderCell: (props) => (
        <RefreshButton id = {props.id} disabled = {!props.row["allowed"]}/>
    ),
  },

];
