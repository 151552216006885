import { useMutation, useQueryClient } from "react-query";
import { useApi } from "./api";
export function useEditBookingRemarks() {
  const { patch } = useApi();
  const patchBookingRemarks = async ({ bookingId, remarks }) => {
    await patch(`bookings/${bookingId}`, { remarks });
    return { bookingId, remarks };
  };
  const queryClient = useQueryClient();
  const onRemarksChanged = ({ bookingId, remarks }) => {
    queryClient.setQueryData(["booking", bookingId], (oldData) => ({
      ...oldData,
      remarks,
    }));
  };
  const {
    mutateAsync: updateRemarks,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(patchBookingRemarks, {
    mutationKey: "editBookingRemarks",
    onSuccess: onRemarksChanged,
  });
  return { updateRemarks, isError, isLoading, isSuccess };
}

export function useEditTripRemarks() {
  const { patch } = useApi();
  const patchTripRemarks = async ({ tripId, remarks }) => {
    await patch(`trips/${tripId}`, { trip_remarks: remarks });
    return { tripId, remarks };
  };
  const queryClient = useQueryClient();
  const onRemarksChanged = async ({ tripId, remarks }) => {
    queryClient.setQueryData(["trip", tripId], (oldData) => ({
      ...oldData,
      remarks: remarks,
    }));
  };
  const {
    mutateAsync: updateRemarks,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(patchTripRemarks, {
    mutationKey: "editTripRemarks",
    onSuccess: onRemarksChanged,
  });
  return { updateRemarks, isError, isLoading, isSuccess };
}
