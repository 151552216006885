import "./PageHeader.css";
import { PropTypes } from "prop-types";
PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.any,
};
export default function PageHeader(props) {
  return (
    <div className="mobi-tui-pageheader">
      <div className="title">
        <div className="t-label">{props.subtitle}</div>
        <div className="t-title mb-l" role="heading" aria-level="1">
          {props.title}
        </div>
      </div>

      <div className="actions">
        {props.actions}
        {props.children}
      </div>
    </div>
  );
}
