import { PropTypes } from "prop-types";
import { BookingIcon } from "components/icons/Icon";
import StatusLabel from "components/card/card-content/StatusLabel";
import Card from "components/card/Card";
import ListItem from "components/card/card-content/list-item/ListItem";
import Loading from "components/loading/Loading";

SidebarContentForSingleBooking.propTypes = {
  bookingCode: PropTypes.string,
  area: PropTypes.string,
  hotel: PropTypes.string,
  status: PropTypes.string,
  passengerName: PropTypes.string,
  passengers: PropTypes.array,
  passengersLabel: PropTypes.string,
  loading: PropTypes.bool,
  fetching: PropTypes.bool,
};
export default function SidebarContentForSingleBooking(props) {
  return (
    <>
      <div className="info">
        {props.loading ? (
          <Loading show={true} />
        ) : (
          <>
            {props.icon ? props.icon : <BookingIcon />}
            <div className="t-title xs label">
              Booking {props.bookingCode}, {props.passengersLabel}
            </div>
            <div className="t-title sm title" role="heading" aria-level={1}>
              {props.passengerName}
            </div>

            <StatusLabel text={props.status} />
            <div className="t-title xs title">{props.hotel}</div>
            <div className="t-title xs label">Area: {props.area}</div>
          </>
        )}
      </div>
      <Card
        loading={props.loading}
        className="card-passenger-list card_scrollable"
      >
        {(props.passengers ?? []).map((passenger) => (
          <ListItem
            icon={passenger.icon}
            key={passenger.id}
            title={passenger.name}
          />
        ))}
      </Card>
    </>
  );
}
