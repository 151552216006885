import { useQuery } from "react-query";

export function useStatus(props) {
  const getStatus = async () => {
      const options = [
      {value: "deferred", label:0}, 
      {value: "scheduled", label: 1}, 
      {value: "started", label: 2}, 
      {value: "finished", label:3},
      {value: "failed", label:4}
    ];
    return options;
  };
  const { data: availableStatus } = useQuery(
    "status",
    getStatus,
    { staleTime: Infinity, placeholderData: [] }
  );
  return { availableStatus };
}
