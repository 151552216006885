export default function Page(props) {
  return (
    <div
      className="page mobi-tui-pagewrap"
      role="region"
      aria-label="page"
      onClick={props.onClick}
      hidden={!props.show}
    >
      {props.children}
    </div>
  );
}
