// import UserImg from "../../images/img-user.png";
import { usePopperTooltip } from "react-popper-tooltip";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import iconTrip from "../../images/icon-ph-avatar.png";

export function User(props) {
  const { logout: logoutAction } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    interactive: true,
    trigger: "click",
    closeOnOutsideClick: false,
    visible: isOpen,
  });

  const logout = () => {
    logoutAction({
      returnTo: window.location.origin,
    });
  };

  return (
    <div 
      className={`top-nav-user-container ${isOpen ? "isopen" : ""}`}
    >
      <div
        ref={setTriggerRef}
        style={{ display: "inline-block" }}
        onClick={toggle}
      >
        <div className="user">
          <div className="avatar">
            <img src={iconTrip} alt="avatar" />
          </div>
          <div className="menu">
            Hi, <b>User</b>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          <ul className="top-nav-user-container__dropdown">
            <li>
              <button onClick={logout}>Logout</button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
