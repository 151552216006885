import { useQuery } from "react-query";
import { useHeaderFilters } from "hooks/useHeaderFilters";
import { useApi } from "hooks/api";
export default function useVehicles() {
  const { selectedDestination, selectedDate } = useHeaderFilters();
  const { get } = useApi();
  const getVehicles = async () => {
    const data = await get("vehicles", {
      destination: selectedDestination,
      date: selectedDate,
    });
    return data;
  };
  const query = useQuery(
    ["vehicles", { selectedDestination, selectedDate }],
    getVehicles,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      placeholderData: [],
    }
  );
  return query;
}
