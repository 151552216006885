import { IconCalendar } from "components/icons/IconCalendar";
import Datepicker from "components/date-picker/datepicker";
import Button from "components/button/Button";
import { dateOnly } from "../../util/util";
import { useHeaderFilters } from "hooks/useHeaderFilters";

const renderDatePickerTrigger = (date) => {
  let s = "Full list";
  if (date) {
    s = dateOnly(date);
  }
  return (
    <Button ariaLabel="datepicker" variant="default outline calendar">
      <span className="tui-date-filter-value">
        <IconCalendar />
        <strong>{s}</strong>
      </span>
    </Button>
  );
};
export function HeaderDatePicker(props) {
  const { selectedDate, setDate } = useHeaderFilters();
  const handleSetDate = (date) => {
    const dateOnly = new Date(date.toDateString());
    setDate(dateOnly);
  };
  return (
    <Datepicker
      initialDate={selectedDate}
      onChange={handleSetDate}
      renderTrigger={renderDatePickerTrigger}
    />
  );
}

export default HeaderDatePicker;
