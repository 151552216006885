import { useQuery } from "react-query"
import { useHeaderFilters } from "./useHeaderFilters"
import { getRQjobsViewDataForUi } from "../util/rqjobs"
import { useApi } from "./api"
export function useRQjobs({ id } = {}) {// query database and return the data
  const {
    selectedStatus,  //started, deferred, finished, failed, scheduled
    // selectedDate,
  } = useHeaderFilters()
  const { get } = useApi()
  const getRQjobs = async () => {
    // http and https compatibility
    const data = await get("get_all_rq_jobs", {
      status: selectedStatus, // params
    })
    return getRQjobsViewDataForUi(data)
  }
  const query = useQuery(
    [
      "rqjobs",
      {
        selectedStatus,
      },
    ],
    getRQjobs,
    {
      placeholderData: [],
      staleTime: 5000,
      keepPreviousData: true,
      retry: 2,
    }
  )
  return {
    ...query,
    showLoading: query.isLoading || query.isFetching,
  }
}
export default useRQjobs
