import Button from "./Button"
import { useApi } from "hooks/api"
import { useQuery } from "react-query"


export default function RefreshButton(props) {
  const { get } = useApi()
  const refreshMD = async () => {
    const result = await get('destinations/pull_md_from_api', {id: props.id})
    return result
  }

 const handleClick = () => {
    console.log("fetching data")
    // (!) manually refetch on click event
    refetch();
  };

  const { isLoading, isRefetching, refetch, isFetching} = useQuery(
    ["refresh_md", props.id],
    refreshMD,
    {
      // placeholderData: "refresh failed",
      keepPreviousData: false,
      staleTime: 10000,
      enabled: false, // (!) handle refetchs manually, won't run when load the page
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  );
  return <Button disabled={props.disabled} loading={isLoading || isFetching || isRefetching} onClick={handleClick}>Refresh {props.id}</Button>;

}