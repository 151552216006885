import Button from "components/button/Button";
import NotifBar from "components/notif-bar/NotifBar";
import { PropTypes } from "prop-types";
import { Slide } from "@material-ui/core";
Notification.propTypes = {
  open: PropTypes.bool,
  onClickAction: PropTypes.func,
  message: PropTypes.string,
};
const slideTransition = (props) => {
  return <Slide {...props} direction="down" timeout={120} />;
};
export default function Notification(props) {
  return (
    <div>
      <NotifBar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        action={
          <Button
            onClick={props.onClickAction}
            variant={props.buttonVariant ?? "default fill"}
          >
            View
          </Button>
        }
        open={props.open}
        message={props.message}
        key="notif"
        TransitionComponent={slideTransition}
      />
    </div>
  );
}
