import LocationSelector from "components/location-selector/LocationSelector";
import { useHeaderFilters } from "hooks/useHeaderFilters";
export function HeaderLocationPicker(props) {
  const { selectedDestination, onDestinationChange, availableDestinations } =
    useHeaderFilters();
  return (
    <LocationSelector
      value={selectedDestination}
      options={availableDestinations}
      onChange={onDestinationChange}
    />
  );
}
export default HeaderLocationPicker;
