import { useQuery } from "react-query";
import { useApi } from "./api";
export function useDestinationsHeader(props) {
    const {get} = useApi();
    const getDestinations = async () => {
        const destinations = await get("destinations?allowed=true");
        const options = [];

        if (destinations) {
            for (let i = 0; i < destinations.length; i++) {
                let destination = destinations[i];
                if (destination.name) {
                    options.push({
                        value: destination.id,
                        label: destination.name,
                    });
                }
            }
        }

        return options;
    };
    const {data: availableDestinations} = useQuery(
        "destinations",
        getDestinations,
        {staleTime: Infinity, placeholderData: []}
    );
    return {availableDestinations};
}