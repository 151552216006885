import TripDetailBookings from "./BookingsView"
import useTripById from "hooks/useTripById"
import { PropTypes } from "prop-types"
import { useState } from "react"
import AddBookings from "../../trip-detail-add-bookings/AddBookings"
import { useContext } from "react"
import { TripSelectionContext } from "../../../../context/TripSelectionContext"
import { useBookings } from "hooks/useBookings"
import { TableContextProvider } from "context/TableContext"
import { BookingSelectionProvider } from "../../../../context/BookingSelectionContext"
BookingsViewContainer.propTypes = {
  onClickBack: PropTypes.func,
  onClickClose: PropTypes.func,
  onClickAdd: PropTypes.func,
  busCode: PropTypes.string,
}
const filterFields = [
  { field: "flightNumber", label: "Flight" },
  { field: "flightWindow", label: "Time" },
  {
    field: "assignedTripSign",
    label: "Trip",
    fixed: true,
  },
  { field: "area", label: "Area" },
  { field: "hotel", label: "Hotel" },
  { field: "tO", label: "T.O." },
  { field: "status", label: "Status" },
]
export default function BookingsViewContainer(props) {
  const { selectedTripId } = useContext(TripSelectionContext)
  const { data: bookingsListData, showLoading } = useBookings({
    tripId: selectedTripId,
  })

  const { data: tripDetail } = useTripById(selectedTripId)
  const { sign, tO = [] } = tripDetail

  const [showAddBookings, setShowAddBookings] = useState(false)
  const openAddBookings = () => setShowAddBookings(true)
  const closeAddBookings = () => setShowAddBookings(false)
  return (
    <div>
      <TableContextProvider
        data={bookingsListData}
        filterFields={filterFields}
        initialFilters={{ assignedTripSign: [sign], tO }}
      >
        {showAddBookings ? (
          <BookingSelectionProvider>
            <AddBookings
              onClickCancel={closeAddBookings}
              bookingIdsCurrentlyInTrip={
                new Set(bookingsListData.map((item) => item.id))
              }
              onAddBookingsDone={closeAddBookings}
            />
          </BookingSelectionProvider>
        ) : (
          <TripDetailBookings
            {...props}
            loading={showLoading}
            bookingsListItems={bookingsListData}
            onClickAdd={openAddBookings}
            tripId={selectedTripId}
          />
        )}
      </TableContextProvider>
    </div>
  )
}
