import iconTrip from "../../images/icon-trip.png";
import iconBooking from "../../images/icon-booking.png";
import iconFlight from "../../images/icon-flight.svg";
import iconHotel from "../../images/icon-hotel.svg";
import iconNotification from "../../images/icon-notification.png";
import iconBaby from "../../images/icon-baby-res.png";
import iconBabyTransp from "../../images/icon-baby-transparent.png";
import iconMap from "../../images/map-trifold-fill.svg";

import "./Icon.css";

// minibus icon: https://www.flaticon.com/free-icon/minibus_62444
export default function ListIcon() {
  return (
    <div className="icon">
      <img src={iconTrip} alt="" />
    </div>
  );
}

const NotificationIcon = () => (
  <div className="icon-header">
    <img src={iconNotification} alt="notification icon" />
  </div>
);
const BookingIcon = () => (
  <div className="icon">
    <img src={iconBooking} alt="passenger icon" />
  </div>
);

const BabyIcon = () => (
  <div className="icon">
    <img src={iconBaby} alt="baby icon" />
  </div>
);
const BabyIconTransparent = () => (
  <div className="icon noborder">
    <img src={iconBabyTransp} alt="baby icon" />
  </div>
);

const BusIcon = () => (
  <div className="icon">
    <img src={iconTrip} alt="" />
  </div>
);

const MapIcon = () => (
  <div className="icon">
    <img src={iconMap} alt="" />
  </div>
);

const IconWrapper = (props) => (
  <div className="icon-wrapper">{props.children}</div>
);
const FlightIcon = () => (
  <IconWrapper>
    <img src={iconFlight} alt="flight icon" />
  </IconWrapper>
);

const HotelIcon = () => (
  <IconWrapper>
    <img src={iconHotel} alt="" />
  </IconWrapper>
);
export {
  BookingIcon,
  BabyIcon,
  BusIcon,
  FlightIcon,
  HotelIcon,
  NotificationIcon,
  IconWrapper,
  BabyIconTransparent,
  MapIcon,
};
