import "./HeaderButton.css";
import { PropTypes } from "prop-types";
import Button from "../button/Button";
HeaderButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  loading: PropTypes.bool,
};
export default function HeaderButton(props) {
  return (
    <Button
      loading={props.loading}
      variant={props.variant}
      className="b-pagebutton"
      text={props.text}
      onClick={props.onClick}
      ref={props.ref}
    />
  );
}
