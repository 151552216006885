import { useApi } from "hooks/api"
import { getBookingDetailDataForUi, emptyBookingDetail } from "../util/bookings"
import { useQuery } from "react-query"
export default function useBookingById(id) {
  const { get } = useApi()
  const getBookingDetail = async () => {
    const data = await get(`bookings/${id}`)
    return getBookingDetailDataForUi(data)
  }
  const query = useQuery(["booking", id], getBookingDetail, {
    placeholderData: emptyBookingDetail,
    keepPreviousData: false,
    staleTime: 10000,
    enabled: !!id,
  })
  return {
    ...query,
    showLoading: query.isLoading || query.isFetching,
  }
}
