import HeaderButton from "../../../components/page-header/HeaderButton";
import { PropTypes } from "prop-types";
ReassignMultipleBookingsButton.propTypes = {
  numBookingsSelected: PropTypes.number,
  onClickReassign: PropTypes.func,
};
export default function ReassignMultipleBookingsButton(props) {
  return props.numBookingsSelected >= 0 ? (
    <HeaderButton
      text={`Reassign ${props.numBookingsSelected} Bookings`}
      onClick={props.onClickReassign}
    />
  ) : (
    <></>
  );
}
