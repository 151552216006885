import Badge from "../components/badge/Badge";
import { BookingIcon } from "components/icons/Icon";
import { formatTimeWindowForUi } from "./util";
import StatusLabel from "../components/card/card-content/StatusLabel";
import { BabyIcon } from "components/icons/Icon";
export const BookingStatus = Object.freeze({
  BEFORE_START: "Before Start",
  BOARDED_BUS: "Boarded",
  DROPPED_OFF: "Dropped Off",
});
export function getBookingsViewDataForUi(dataFromApi = []) {
  function toBookingsListItem(item = {}) {
    return {
      arrival: item.arrival,
      key: item.id,
      id: item.id,
      pax: item.pax,
      remarks: item.remarks,
      assignedTripSign: item.trip_sign == null ? "Unassigned" : item.trip_sign,
      extBooking: item.ext_booking,
      trip: item.trip,
      tO: item.tour_operator?.name,
      title: item.lead_pax_name,
      label: item.pax.toString(),
      booking: {
        title: item.lead_pax_name ?? "No Name",
        label: item.pax.toString(),
        icon: (
          <Badge color="blue" hide={!!item.trip_sign}>
            <BookingIcon />
          </Badge>
        ),
      },
      status: item.boarded_bus
        ? item.dropped_off
          ? "Dropped Off"
          : "Boarded"
        : "Before Start",
      flightNumber: `${item.flight.code} (${
        item.flight?.home_airport ?? "Unknown"
      })`,
      area: item.area ? item.area : "Unknown",
      hotel: item.hotel ? item.hotel : "Unknown",
      flightBookingTime: new Date(
        item.flight.time + "+00:00"
      ).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }),
      time: item.flight.time,
      flightWindow: formatTimeWindowForUi(item.flight.time),
      destination: String(item.destination),
    };
  }
  return dataFromApi.map(toBookingsListItem);
}
export function getBookingDetailDataForUi(dataFromApi) {
  const {
    id,
    lead_pax_name: passengerName,
    pax = 1,
    passengers = [],
    boarded_bus,
    dropped_off,
    area,
    hotel,
    ext_booking,
    remarks,
    trip,
    infant,
  } = dataFromApi;
  const infantsCount = passengers.filter((p) => p.infant).length;
  const adultsCount = pax - infantsCount;
  const dataForUi = {
    passengerName,
    trip,
    id,
    pax,
    passengers: passengers.map((passenger) => ({
      ...passenger,
      icon: passenger.infant ? <BabyIcon /> : <BookingIcon />,
    })),
    bookingCode: ext_booking ? ext_booking : "Unknown",
    passengersLabel: `${adultsCount} Adult${adultsCount > 1 ? "s" : ""} ${
      infant ? ` + ${infantsCount} Infant${infantsCount > 1 ? "s" : ""}` : ""
    }`,
    status: boarded_bus
      ? dropped_off
        ? "Dropped Off"
        : "Boarded"
      : "Before Start",
    area: area,
    remarks: remarks,
    hotel: hotel,
    infantsCount,
  };
  return dataForUi;
}

export const emptyBookingDetail = {
  passengerName: "",
  pax: "",
  trip: "",
  id: "",
  passengersList: [],
  bookingCode: "",
  numPassengers: "",
  status: "Before Start",
  area: "",
  remarks: "",
  hotel: "",
};

export const bookingsFilterFields = [
  { field: "flightNumber", label: "Flight" },
  { field: "flightWindow", label: "Time" },
  {
    field: "assignedTripSign",
    label: "Trip",
    sortComparator: (value1, value2) => {
      if (value1 === value2) {
        return 0;
      } else if (value1 === "Unassigned") {
        return -1;
      } else if (value2 === "Unassigned") {
        return 1;
      } else return value1 - value2;
    },
  },
  { field: "area", label: "Area" },
  { field: "hotel", label: "Hotel" },
  { field: "tO", label: "T.O." },
  { field: "status", label: "Status" },
];
export const tableColumns = [
  {
    field: "pax",
    headerName: "Pax",
    flex: 10,
  },
  {
    field: "id",
    headerName: "ID",
    flex: 15,
  },
  {
    field: "extBooking",
    headerName: "Booking",
    flex: 8,
  },
  { field: "area", headerName: "Area" },
  { field: "hotel", headerName: "Hotel" },
  {
    field: "tO",
    headerName: "T.O.",
    flex: 7,
  },
  {
    field: "assignedTripSign",
    headerName: "Trip",
    flex: 7,
    renderCell: (params) => params.value.toString(),
    sortComparator: (value1, value2) => {
      if (value1 === value2) {
        return 0;
      } else if (value1 === "Unassigned") {
        return -1;
      } else if (value2 === "Unassigned") {
        return 1;
      } else return value1 - value2;
    },
  },
  { field: "flightNumber", headerName: "Flight (IATA)" },
  {
    field: "flightBookingTime",
    headerName: "Time",
    flex: 7,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => (
      <div>{params.value ? <StatusLabel text={params.value} /> : <></>}</div>
    ),
    flex: 11,
    headerClassName: "t-table-header_padded",
  },
];
