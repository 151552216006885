import { useMutation } from "react-query"
import { useApi } from "./api"
import { useHeaderFilters } from "./useHeaderFilters"
import { useInstance } from "./useInstance"
import { useQueryClient } from "react-query"
export default function useCreateTrip() {
  const {
    selectedDirectionIsToAirport,
    selectedDirection,
    selectedDate,
    selectedDestination,
  } = useHeaderFilters()
  const { id: instanceId } = useInstance()
  const { post } = useApi()
  const queryClient = useQueryClient()
  const {
    mutateAsync: createTrip,
    data,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(
    async ({ sign, remarks, vehicle, bookingIds }) => {
      const { id: tripId } = await post("trips", {
        sign,
        trip_remarks: remarks,
        vehicle,
        to_airport: selectedDirectionIsToAirport,
        instance: instanceId,
        bookings: JSON.stringify(bookingIds),
        force: true
      })
      return { tripId }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("trips", {
          selectedDate,
          selectedDestination,
          selectedDirection,
        })
      },
    }
  )
  return { createTrip, data, isError, isLoading, isSuccess }
}
