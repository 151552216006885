import './Alert.css'
import { PropTypes } from 'prop-types';
Alert.propTypes = {
    show: PropTypes.bool, 
    className: PropTypes.string
}
export default function Alert(props){
    if (props.show){
        return <div className={`alert t-title xxs ${props.className ?? ''}`}>
            {props.children}
        </div>
    } else return <></>
}