import PageHeader from "../../../components/page-header/PageHeader";
import { PropTypes } from "prop-types";
import HeaderButton from "../../../components/page-header/HeaderButton";
import { useContext } from "react";
import { useBookings } from "hooks/useBookings";
import FilterBar from "components/filter-bar/FilterBar";
import Table from "components/table/Table";
import {
  TableContextConsumer,
  TableContextProvider,
} from "context/TableContext";
import { bookingsFilterFields, tableColumns } from "util/bookings";
import { BookingSelectionContext } from "../../../context/BookingSelectionContext";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { TripSelectionContext } from "../../../context/TripSelectionContext";
import useAssignBookings from "../../../hooks/useAssignBookings";
import useFilters from "../../../hooks/useFilters";

AddBookings.propTypes = {
  bookingsListItems: PropTypes.array,
  bookingsLoading: PropTypes.bool,
  onClickAdd: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickBack: PropTypes.func,
  bookingQuery: PropTypes.object,
};
export default function AddBookings(props) {
  const { data: allBookings, isLoading } = useBookings();
  const { filteredData: unassignedBookings } = useFilters(
    allBookings,
    bookingsFilterFields,
    {
      assignedTripSign: "Unassigned",
    }
  );
  const { selectedBookingIds, setSelectedBookings } = useContext(
    BookingSelectionContext
  );
  const { selectedTripId } = useContext(TripSelectionContext);
  const { assignBookings, isLoading: assignIsLoading } = useAssignBookings();
  const { openSnackbar } = useContext(SnackbarContext);

  const onClickAdd = async () => {
    try {
      await assignBookings({
        bookingIds: selectedBookingIds,
        tripId: selectedTripId,
        force: true
      });
      props.onAddBookingsDone();
      openSnackbar({ message: "Bookings added", severity: "success" });
    } catch (e) {
      console.error(e);
      openSnackbar({ message: "Error adding bookings", severity: "error" });
    }
  };
  return (
    <div>
      <PageHeader title="Add Bookings">
        <HeaderButton
          onClick={props.onClickCancel}
          variant="light fill"
          text="Cancel"
        />
        ,
        <HeaderButton
          key="2"
          variant="default fill"
          text="Add"
          onClick={onClickAdd}
          loading={assignIsLoading}
        />
      </PageHeader>
      <TableContextProvider
        filterFields={bookingsFilterFields}
        data={unassignedBookings}
      >
        <TableContextConsumer>
          <FilterBar withSearch={false} />
          <Table
            columns={tableColumns}
            showActions={false}
            enableSelect={true}
            loading={isLoading}
            selectionModel={selectedBookingIds}
            onSelectionChange={setSelectedBookings}
          />
        </TableContextConsumer>
      </TableContextProvider>
    </div>
  );
}
