import iconBus from '../../images/bus-light.svg'
import iconTaxi from '../../images/taxi-light.svg'
import iconMinibus from '../../images/van-light.svg'
import ColoredIcon from './ColoredIcon';


export function BusStatusIcon(props){
    return <ColoredIcon color={props.color}>
        <img src={iconBus} alt="" />
    </ColoredIcon>
}

export function MinibusStatusIcon(props){
    return <ColoredIcon color={props.color}>
        <img src={iconMinibus} alt="" />
    </ColoredIcon>
}

export function TaxiStatusIcon(props){
    return <ColoredIcon color={props.color}>
        <img src={iconTaxi} alt="" />
    </ColoredIcon>
}