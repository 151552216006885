import "./global.css";
import "react-dates-fns/initialize";

import { QueryClient, QueryClientProvider } from "react-query";

import Loading from "./components/loading/Loading";
import LogRocket from "logrocket";
import { SingletonHooksContainer } from "react-singleton-hook";
import { Views } from "./views/Views";

//import { useAuth0 } from "@auth0/auth0-react";

function App() {
  // const {
  //   //isAuthenticated,
  //   //loginWithRedirect,
  //   isLoading,
  //   user: {
  //     sub: userId,
  //     name: userName,
  //     "https://www.takemobi.com/client_metadata": client_metadata,
  //   } = { sub: "no userId" },
  // } = useAuth0();

  // if (client_metadata) {
  //   localStorage.setItem("x-api-key", client_metadata["x-api-key"]);
  // }

  // if (!isAuthenticated && !isLoading) {
  //   loginWithRedirect();
  //   return <div />;
  // }
  // if (isLoading) {
  //   return <Loading />;
  // }

  // LogRocket.init("8cmz5i/tui-dashboard");
  // LogRocket.identify(userId, { name: userName });

  const version = process.env.REACT_APP_VERSION;
  const queryClient = new QueryClient();
  return (
    <div className="App" role="application">
      <QueryClientProvider client={queryClient}>
        <SingletonHooksContainer></SingletonHooksContainer>
        <header className="App-header"></header>
        <Views />
      </QueryClientProvider>
    </div>
  );
}

export default App;
