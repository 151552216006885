import { TRIP_NOTIF_COLOR, BOOKING_NOTIF_COLOR } from "../util/notifications";
import useBookings from "hooks/useBookings";
import { useTrips } from "hooks/useTrips";
import useFilters from "./useFilters";
export default function useNotifications() {
  const { data: trips } = useTrips();
  const { data: bookings } = useBookings();
  const { numFilterResults: numUnfeasibleTrips } = useFilters(trips, [], {
    feasible: "Unfeasible",
  });
  const { numFilterResults: numUnassignedBookings } = useFilters(bookings, [], {
    assignedTripSign: "Unassigned",
  });

  const unfeasibleTripsMessage = `${numUnfeasibleTrips} Unfeasible Trips`;
  const unassignedBookingsMessage = `${numUnassignedBookings} Unassigned Bookings`;

  const notifIconTrips = {
    hasNotifs: numUnfeasibleTrips > 0,
    count: numUnfeasibleTrips,
    color: TRIP_NOTIF_COLOR,
  };
  const notifIconBookings = {
    hasNotifs: numUnassignedBookings > 0,
    count: numUnassignedBookings,
    color: BOOKING_NOTIF_COLOR,
  };
  return {
    numUnfeasibleTrips,
    unfeasibleTripsMessage,
    numUnassignedBookings,
    unassignedBookingsMessage,
    notifIconTrips,
    notifIconBookings,
  };
}
