import { v4 as uuidv4 } from "uuid";

export function getUniqueValuesForKey(arr = [], key) {
  return Array.from(new Set(arr.flatMap((arrayItem) => arrayItem[key]))).sort(
    (a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      if (a === 0 && b !== 0) return 1;
      if (a !== 0 && b === 0) return -1;
      return 0;
    }
  );
}

export const EMPTY_FILTER_VALUE = Symbol.for("empty");
export function filterData(data = [], filters = {}) {
  let filteredData = data;
  const filterKeys = Object.keys(filters);
  filteredData = data.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key]?.length) return true;
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      return (
        filters[key].includes(product[key]) ||
        filters[key].includes(product[key] + "")
      );
    });
  });
  return filteredData;
}
export const makeDefaultFilters = (filterCategories) => {
  return Object.assign(...filterCategories.map((key) => ({ [key]: [] })));
};

export function filterItemsFromData(filterCategories, data) {
  return filterCategories.map(
    ({ field, label, sortComparator, fixed = false }) => ({
      label,
      category: field,
      fixed,
      options: getUniqueValuesForKey(data, field)
        .sort(sortComparator)
        .map((option) => ({
          value: option,
          label: String(option),
          key: uuidv4(),
        }))
        .sort(sortComparator),
      key: uuidv4(),
    })
  );
}
