import { createContext, useState } from "react";
const _ = require("lodash");
export const BookingSelectionContext = createContext({
  selectedBookingIds: [],
});
export function BookingSelectionProvider(props) {
  const [selectedBookingIds, setSelectedBookingIds] = useState([]);
  const selectBooking = (bookingId) => {
    setSelectedBookingIds([bookingId]);
  };
  const addSelectedBookings = (bookingIds) => {
    setSelectedBookingIds(_.union(selectedBookingIds, bookingIds));
  };

  const removeSelectedBookings = (bookingIds) => {
    setSelectedBookingIds(_.difference(selectedBookingIds, bookingIds));
  };
  const clearSelectedBookings = () => setSelectedBookingIds([]);
  const areAnyBookingsSelected = selectedBookingIds.length > 0;
  const areMultipleBookingsSelected = selectedBookingIds.length > 1;
  const numBookingsSelected = selectedBookingIds.length;
  return (
    <BookingSelectionContext.Provider
      value={{
        selectedBookingIds,
        selectBooking,
        setSelectedBookings: setSelectedBookingIds,
        addSelectedBookings,
        removeSelectedBookings,
        areAnyBookingsSelected,
        clearSelectedBookings,
        numBookingsSelected,
        areMultipleBookingsSelected,
      }}
    >
      {props.children}
    </BookingSelectionContext.Provider>
  );
}
export default BookingSelectionContext;
