import { createContext, useState } from "react";
export const SnackbarContext = createContext({});
export function SnackbarProvider(props) {
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "success",
    open: false,
  });
  const openSnackbar = ({ message, severity }) => {
    setSnackbar({
      message,
      severity,
      open: true,
    });
  };
  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  return (
    <SnackbarContext.Provider
      value={{
        snackbar: {
          ...snackbar,
        },
        openSnackbar,
        closeSnackbar,
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  );
}
