import { PropTypes } from 'prop-types';
ListItemInfo.propTypes = {
    label:PropTypes.string,
    title:PropTypes.string,
    copy: PropTypes.string,
}
export default function ListItemInfo(props) {
    return <div className='item-info'>
        <div className='t-label'>{props.label}</div>
        <div className='t-title xs'>{props.title}</div>
        <div className='t-copy xs'>{props.copy}</div>
    </div>
}
export {ListItemInfo}