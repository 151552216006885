import { useQuery } from "react-query";
import { useHeaderFilters, makeDateForAPI } from "./useHeaderFilters";
import { toTripListData } from "../util/trips";
import { useApi } from "./api";
export function useTrips(filters = {}) {
  const {
    selectedDestination,
    selectedDate,
    selectedDirection,
    selectedDirectionIsToAirport,
  } = useHeaderFilters();
  const { get } = useApi();
  const getTripData = async () => {
    const data = await get("trips", {
      to_airport: selectedDirectionIsToAirport,
      instance__destination: selectedDestination,
      instance__date: makeDateForAPI(selectedDate),
      calculate_cost: true,
    });
    return toTripListData(data);
  };
  const query = useQuery(
    ["trips", { selectedDestination, selectedDate, selectedDirection }],
    getTripData,
    { placeholderData: [], staleTime: 5000, keepPreviousData: true }
  );
  return { ...query, showLoading: query.isLoading || query.isFetching };
}
export default useTrips;
