import { useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { headerDefaults } from "util/header";
import { useDestinationsHeader } from "./useDestinationsHeader";
import { useStatus } from "./useStatus";

// Delete the '2021-09-21' date when we have more data
export const EMPTY_SELECTED_DATE = new Date(new Date('2021-09-25').toDateString());
export const makeDateForAPI = (date) => {
  if (!date) {
    return "";
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

console.log(EMPTY_SELECTED_DATE);

const defaultHeaderFiltersValue = {
  availableStatus: [],
  selectedStatus: "started",
  availableDestinations: [],
  selectedDate: EMPTY_SELECTED_DATE,
  selectedEndDate: EMPTY_SELECTED_DATE,
  selectedDestination: headerDefaults.DEFAULT_SELECTED_DESTINATION,
  // 'from airport' or 'to airport'
  selectedDirection: "from airport",
  selectedDirectionIsArrival: true,
  selectedDirectionIsToAirport: false,
};
export function useHeaderFiltersImpl() {
  const [selectedDate, setDate] = useState(
    defaultHeaderFiltersValue.selectedDate
  );
  const [selectedEndDate, setEndDate] = useState(
      defaultHeaderFiltersValue.selectedEndDate
  );
  const [selectedDirection, setDirection] = useState(
    defaultHeaderFiltersValue.selectedDirection
  );
  const selectedDirectionIsArrival = {
    "from airport": true,
    "to airport": false,
  }[selectedDirection];
  const selectedDirectionIsToAirport = {
    "to airport": true,
    "from airport": false,
  }[selectedDirection];
  const [selectedDestination, setSelectedDestination] = useState(
    defaultHeaderFiltersValue.selectedDestination
  );
  const onDestinationChange = (destination) => {
    setSelectedDestination(destination);
  };
  const [selectedStatus, setSelectedStatus] = useState(
    defaultHeaderFiltersValue.selectedStatus
  );
  const onStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const { availableDestinations } = useDestinationsHeader();
  const {availableStatus} = useStatus();

  return {
    selectedDestination,
    onDestinationChange,
    availableDestinations,
    selectedDate,
    setDate,
    selectedEndDate,
    setEndDate,
    selectedDirection,
    selectedDirectionIsArrival,
    selectedDirectionIsToAirport,
    setDirection,
    selectedStatus,
    availableStatus,
    onStatusChange,
  };
}

export const useHeaderFilters = singletonHook(
  defaultHeaderFiltersValue,
  useHeaderFiltersImpl
);
export default useHeaderFilters;
