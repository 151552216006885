import { PropTypes } from "prop-types";
import Card from "components/card/Card";
import ListItem from "components/card/card-content/list-item/ListItem";
import CardDataItem from "components/card/card-content/card-data-item/CardDataItem";
import Button from "components/button/Button";
import { BusIcon } from "components/icons/Icon";
TripCard.propTypes = {
  loading: PropTypes.bool,
  feasibility: PropTypes.string,
  departureTime: PropTypes.string,
  onClickReassignTrip: PropTypes.func,
  onClickRemoveTrip: PropTypes.func,
};
export default function TripCard({
  vehicle,
  loading,
  feasible,
  tripCardTitle,
  departureTime,
  onClickReassignTrip,
  onClickRemoveTrip,
  status,
  hasTripData,
  assignInProgress,
  removeInProgress
}) {
  const onClickRemove = () => {
    onClickRemoveTrip()
  }
  if (hasTripData) {
    return (
      <>
        <Card loading={loading}>
          <ListItem icon={<BusIcon />} title={tripCardTitle} label="" showArrow={true} />
          <CardDataItem field="Feasibility" value={feasible} />
          <CardDataItem field="Vehicle" value={vehicle} />
          <CardDataItem field="Departing" value={departureTime} />
          <CardDataItem field="Status" value={status} />
          <div className="btn-group-horiz">
            <Button variant="default fill long" text="Reassign" onClick={onClickReassignTrip} loading={assignInProgress} />
            <Button variant="negative fill short" text="Remove" onClick={onClickRemove} loading={removeInProgress}/>
          </div>
        </Card>
      </>
    );
  } else {
    return (
      <>
        <Card loading={loading}>
          <ListItem
            icon={<BusIcon />}
            content={<span className="t-copy red">No Trip Assigned</span>}
            showArrow={false}
          ></ListItem>
          <Button variant="default fill full-width" text="Assign Trip" onClick={onClickReassignTrip} />
        </Card>
      </>
    );
  }
}
