import { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import "./MultiSelect.css";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = memo(
  ({
    id,
    options,
    label = "label",
    fixed,
    onChange,
    clearHandler,
    values = [],
  }) => {
    // const [multiValues, setMultiValues] = useState([]);

    const cls = values.length > 0 ? "dropdown active" : "dropdown";
    const classes = useStyles();

    const handleChange = (event) => {
      onChange(event);
    };

    const handleDelete = (option) => () => {
      clearHandler(option);
    };
    if (options.length === 0)
      return (
        <div className="dropdown disabled" disabled>
          <div className="MuiFormControl-root">
            <div className="MuiInputBase-root MuiInput-root ">
              <div className="MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input">
                <div>{label}</div>
              </div>
            </div>
          </div>
        </div>
      );

    return (
      <div className={cls} role="menuitem">
        <FormControl
          key={label}
          className={classes.formControl}
          disabled={fixed}
        >
          <Select
            className={`selector select-${label}`}
            labelId={`mutiple-chip-label-${id}`}
            id={`mutiple-chip-${id}`}
            multiple
            value={values}
            onChange={handleChange}
            displayEmpty
            disableUnderline
            input={<Input id={`select-multiple-chip-${id}`} />}
            renderValue={(_selected) => <div>{label}</div>}
            MenuProps={MenuProps}
          >
            {options.map((optItem) => {
              return (
                <MenuItem key={optItem.label} value={optItem.label}>
                  <Checkbox
                    icon={<CircleUnchecked />}
                    color="primary"
                    checkedIcon={<CircleCheckedFilled />}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    checked={values.indexOf(optItem.label) > -1}
                  />
                  <ListItemText primary={optItem.label} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {/* CHIPS */}
        <div className="chips">
          <h3
            style={{ display: "none", fontSize: "11px", maxWidth: "150px" }}
            hidden
          >
            MultiValues:{" "}
          </h3>
          {values.map((option) => {
            return (
              <Chip
                size="small"
                label={option}
                key={option}
                onDelete={fixed ? undefined : handleDelete(option)}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

export default MultiSelect;
