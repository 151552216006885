export const IconCalendar = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9788 1.58811H14.0223V1.47983C14.0223 0.667728 13.3546 0 12.5425 0H11.4416C10.6295 0 9.96178 0.667728 9.96178 1.47983V1.58811H7.21868V1.47983C7.21868 0.667728 6.55096 0 5.73885 0H4.638C3.8259 0 3.15817 0.667728 3.15817 1.47983V1.58811H2.18365C0.974522 1.58811 0 2.58068 0 3.77176V14.8163C0 16.0255 0.974522 17 2.18365 17H14.9607C16.1699 17 17.1444 16.0255 17.1444 14.8163V3.77176C17.1624 2.56263 16.1879 1.58811 14.9788 1.58811ZM11.2431 1.47983C11.2431 1.37155 11.3333 1.28132 11.4416 1.28132H12.5425C12.6507 1.28132 12.741 1.37155 12.741 1.47983V3.03185C12.741 3.14013 12.6507 3.23036 12.5425 3.23036H11.4416C11.3333 3.23036 11.2431 3.14013 11.2431 3.03185V1.47983ZM4.43949 1.47983C4.43949 1.37155 4.52972 1.28132 4.638 1.28132H5.73885C5.84713 1.28132 5.93737 1.37155 5.93737 1.47983V3.03185C5.93737 3.14013 5.84713 3.23036 5.73885 3.23036H4.638C4.52972 3.23036 4.43949 3.14013 4.43949 3.03185V1.47983ZM15.8089 14.8163C15.8089 15.2675 15.448 15.6465 14.9788 15.6465H2.2017C1.75053 15.6465 1.37155 15.2675 1.37155 14.8163V6.35244H15.8089V14.8163Z" fill="#2C70C9"/>
      <path d="M3.53716 10.2686H5.17941C5.4862 10.2686 5.73885 10.0159 5.73885 9.70913V8.44586C5.73885 8.13907 5.4862 7.88641 5.17941 7.88641H3.53716C3.23036 7.88641 2.97771 8.13907 2.97771 8.44586V9.70913C2.99575 10.0159 3.23036 10.2686 3.53716 10.2686Z" fill="#2C70C9"/>
      <path d="M12.0011 10.2686H13.6433C13.9501 10.2686 14.2028 10.0159 14.2028 9.70913V8.44586C14.2028 8.13907 13.9501 7.88641 13.6433 7.88641H12.0011C11.6943 7.88641 11.4416 8.13907 11.4416 8.44586V9.70913C11.4416 10.0159 11.6943 10.2686 12.0011 10.2686Z" fill="#2C70C9"/>
      <path d="M7.77813 10.2686H9.42038C9.72718 10.2686 9.97983 10.0159 9.97983 9.70913V8.44586C9.97983 8.13907 9.72718 7.88641 9.42038 7.88641H7.77813C7.47134 7.88641 7.21868 8.13907 7.21868 8.44586V9.70913C7.21868 10.0159 7.47134 10.2686 7.77813 10.2686Z" fill="#2C70C9"/>
      <path d="M3.53716 14.1486H5.17941C5.4862 14.1486 5.73885 13.896 5.73885 13.5892V12.3259C5.73885 12.0191 5.4862 11.7665 5.17941 11.7665H3.53716C3.23036 11.7665 2.97771 12.0191 2.97771 12.3259V13.5892C2.99575 13.896 3.23036 14.1486 3.53716 14.1486Z" fill="#2C70C9"/>
      <path d="M12.0011 14.1486H13.6433C13.9501 14.1486 14.2028 13.896 14.2028 13.5892V12.3259C14.2028 12.0191 13.9501 11.7665 13.6433 11.7665H12.0011C11.6943 11.7665 11.4416 12.0191 11.4416 12.3259V13.5892C11.4416 13.896 11.6943 14.1486 12.0011 14.1486Z" fill="#2C70C9"/>
      <path d="M7.77813 14.1486H9.42038C9.72718 14.1486 9.97983 13.896 9.97983 13.5892V12.3259C9.97983 12.0191 9.72718 11.7665 9.42038 11.7665H7.77813C7.47134 11.7665 7.21868 12.0191 7.21868 12.3259V13.5892C7.21868 13.896 7.47134 14.1486 7.77813 14.1486Z" fill="#2C70C9"/>
    </svg>

  );
};
