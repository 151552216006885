import { PropTypes } from 'prop-types';
import './Icon.css'
import './ColoredIcon.css'
ColoredIcon.propTypes = {
    // red, yellow, gray
    color: PropTypes.string,
}
export default function ColoredIcon(props){
    return <div className={`icon icon-${props.color}`}>
        {props.children}
    </div>
}