import "./MenuButton.css";
import { PropTypes } from "prop-types";
MenuButton.propTypes = {
  onClick: PropTypes.func,
};
export default function MenuButton(props) {
  return (
    <div
      className="b-menu"
      aria-label="Menu button"
      role="button"
      onClick={props.onClick}
    ></div>
  );
}
