import PageHeader from "components/page-header/PageHeader"
import FilterBar from "components/filter-bar/FilterBar"
import Button from "components/button/Button"
import HeaderButton from "components/page-header/HeaderButton"
import Table from "components/table/Table"
import { BookingSelectionContext } from "../../context/BookingSelectionContext"
import "./BookingDetail.css"
import { useContext, useState } from "react"
import {
  filterCategories,
  tableColumns as tripsColumns,
} from "../../util/trips"
import { tableColumns as bookingsColumns } from "util/bookings"
import {
  TableContextConsumer,
  TableContextProvider,
} from "context/TableContext"
import useTrips from "../../hooks/useTrips"
import { useBookings } from "hooks/useBookings"
import { filterData } from "../../util/filtering"
import useUnassignBookings from "hooks/useUnassignBookings"
import useAssignBookings from "hooks/useAssignBookings"
import { SnackbarContext } from "context/SnackbarContext"
import { ViewsContext } from "context/ViewsContext"
import { useQueryClient } from "react-query"
import TripSelectionContext from "context/TripSelectionContext"
const _ = require("lodash")
export const BookingDetailPage = () => {
  const { selectedBookingIds, setSelectedBookings } = useContext(
    BookingSelectionContext
  )
  const [bookingSubset, setBookingSubset] = useState(selectedBookingIds)
  const { data: allBookings, isLoading: bookingsLoading } = useBookings()
  const selectedBookingsData = filterData(allBookings, {
    id: selectedBookingIds,
  })
  const { unassignBookings, isLoading: removeInProgress } =
    useUnassignBookings()
  const { assignBookings, isLoading: assignInProgress } = useAssignBookings()
  const { data: trips = [], showLoading } = useTrips()
  const queryClient = useQueryClient()
  const tripsInitiallySelected = selectedBookingsData.map((bkg) => bkg.trip)

  const { selectedTripId, setTripId } = useContext(TripSelectionContext)
  const { data: selectedTripBookings } = useBookings({ tripId: selectedTripId })
  const handleChangesToSelectedTrip = async (bookingsRemoved) => {
    if (bookingsRemoved.length === selectedTripBookings.length) {
      setTripId(undefined)
      queryClient.removeQueries(["trip", selectedTripId])
      queryClient.removeQueries(["bookings", { tripId: selectedTripId }])
    } else {
      await queryClient.refetchQueries(["trip", selectedTripId])
      await queryClient.refetchQueries(["bookings", { tripId: selectedTripId }])
    }
  }

  const { openSnackbar } = useContext(SnackbarContext)
  const handleSuccess = async () => {
    const bookingsRemoved = bookingSubset
    const newSelection = _.difference(selectedBookingIds, bookingSubset)
    setBookingSubset(newSelection)
    setSelectedBookings(newSelection)
    await handleChangesToSelectedTrip(bookingsRemoved)
    openSnackbar({ message: "success", severity: "success" })
  }
  const onClickSelect = async (tripId) => {
    try {
      await assignBookings({ bookingIds: bookingSubset, tripId, force: true })
      handleSuccess()
    } catch (e) {
      console.error(e)
      openSnackbar({ message: "error", severity: "error" })
    }
  }
  const onClickUnassignAll = async () => {
    try {
      await unassignBookings(bookingSubset)
      handleSuccess()
    } catch (e) {
      console.error(e)
      openSnackbar({ message: "error", severity: "error" })
    }
  }
  const flagSelectedTrips = (trips) =>
    trips.map((trip) => ({
      ...trip,
      isInitiallySelected: tripsInitiallySelected.includes(trip.id),
    }))
  const withButtons = flagSelectedTrips(trips).map((trip) => ({
    ...trip,
    actions: trip.isInitiallySelected ? (
      <Button text="Selected" variant="light fill" onClick={(evt) => {}} />
    ) : (
      <Button
        text="Select"
        variant="default fill"
        loading={assignInProgress}
        onClick={(evt) => {
          evt.stopPropagation()
          onClickSelect(trip.id)
        }}
      />
    ),
  }))

  const { closeCurrentView } = useContext(ViewsContext)
  return (
    <>
      <PageHeader title="Reassign Trip" subtitle="Task">
        <HeaderButton
          text="Cancel"
          variant="light fill"
          onClick={closeCurrentView}
        />
        <HeaderButton
          text="Unassign Selected"
          variant="negative fill"
          onClick={onClickUnassignAll}
          loading={removeInProgress}
        />
      </PageHeader>
      <Table
        loading={bookingsLoading}
        rows={selectedBookingsData}
        columns={bookingsColumns}
        showActions={false}
        onClickEdit={() => {}}
        onDetailClick={() => {}}
        tableConfig={{ hideFooter: true }}
        enableSelect={true}
        selectionModel={bookingSubset}
        onSelectionChange={setBookingSubset}
      />
      <TableContextProvider data={withButtons} filterFields={filterCategories}>
        <TableContextConsumer>
          <FilterBar withSearch={true} />
          <Table
            columns={tripsColumns}
            showActions={true}
            enableSelect={false}
            loading={showLoading}
          />
        </TableContextConsumer>
      </TableContextProvider>
    </>
  )
}
