import { PropTypes } from "prop-types";
import "./LocationSelector.css";
import Button from "../button/Button";
import { IconMarker } from "../icons/IconMarker";

LocationSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
export default function LocationSelector(props) {
  const onChange = (evt) => {
    const value = evt.target?.value;
    props.onChange(value);
  };

  const DEFAULT_EMPTY_NAME = "Location without name";

  const renderOptions = () => {
    return (props.options || []).map((x) => {
      return (
        <option key={x.value} value={x.value}>
          {x.label || DEFAULT_EMPTY_NAME}
        </option>
      );
    });
  };

  const getLocationLabel = () => {
    const v = (props.options ?? []).find(
      (x) => x?.value?.toString() === props.value.toString()
    );
    return v ? v.label || DEFAULT_EMPTY_NAME : "Please pick one";
  };

  return (
    <Button
      variant="default outline"
      className="mobi-tui-location-selector"
      ariaLabel="location-selector"
    >
      <div className="mobi-tui-location-selector__btn-content">
        <IconMarker />
        <label>
          <strong>{getLocationLabel()}</strong>
          <select
            value={props.value}
            onChange={onChange}
            className="mobi-tui-location-selector__select-css"
          >
            {renderOptions()}
          </select>
        </label>
        <div className="mobi-tui-location-selector__carret"></div>
      </div>
    </Button>
  );
}
