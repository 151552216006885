import "./Sidebar.css";
import { PropTypes } from "prop-types";
import Loading from "../loading/Loading";
Sidebar.propTypes = {
  loading: PropTypes.bool,
};
export default function Sidebar(props) {
  return (
    <div
      className="sidebar"
      hidden={!props.show}
      role="region"
      aria-label="sidebar"
    >
      {props.children}
      <Loading show={props.loading || !props.children} />
    </div>
  );
}
