import { createContext, useState } from "react";
import _ from "lodash";
require("lodash");
export const DestinationSelectionContext = createContext({
  selectedDestinationIds: [],
});


export function DestinationSelectionProvider(props) {
  const [selectedDestinationIds, setSelectedDestinationIds] = useState([]);
  const selectDestination = (destinationId) => {
      setSelectedDestinationIds([destinationId]);
  };

  const addSelectedDestinations = (destinationIds) => {
      setSelectedDestinationIds(_.union(selectedDestinationIds, destinationIds));
  };

  const removeSelectedDestinations = (destinationIds) => {
      setSelectedDestinationIds(_.difference(selectedDestinationIds, destinationIds));
  };

  const clearSelectedDestinations = () => setSelectedDestinationIds([]);
  const areAnyDestinationsSelected = selectedDestinationIds.length > 0;
  const areMultipleDestinationsSelected = selectedDestinationIds.length > 1;
  const numDestinationsSelected = selectedDestinationIds.length;
  return (
    <DestinationSelectionContext.Provider
      value={{
          selectedDestinationIds,
          selectDestination,
          setSelectedDestinations: setSelectedDestinationIds,
          addSelectedDestinations,
          removeSelectedDestinations,
          areAnyDestinationsSelected,
          clearSelectedDestinations,
          numDestinationsSelected,
          areMultipleDestinationsSelected,
      }}
    >
      {props.children}
    </DestinationSelectionContext.Provider>
  );
}
export default DestinationSelectionContext;
