import { PropTypes } from "prop-types"
import Loading from "../loading/Loading"
import "./Card.css"
Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  isInactive: PropTypes.bool,
}
export default function Card(props) {
  const variant = props.isInactive ? "card-outline" : "card-default"
  return (
    <div
      className={`t-card card ${variant} ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.loading ? <Loading show={true} /> : props.children}
    </div>
  )
}
