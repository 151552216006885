import React, { useContext } from "react"
import SidebarWithPage from "../composable/sidebar-with-page/SidebarWithPage"
import { BookingDetailPage } from "./BookingDetailPage.js"
import { BookingSelectionContext } from "../../context/BookingSelectionContext"
import useBookingById from "../../hooks/useBookingById"
import { ViewsContext } from "../../context/ViewsContext"
import { BookingDetailSidebar } from "./BookingDetailSidebar"
export default function BookingDetailContainer({
  openSnackbar,
  openSnackBar,
  setOpenSnackbar,
  snackBarMsg,
  setSnackBarMsg,
  ...props
}) {
  const { isVisibleSidebar, isVisiblePage } = useContext(ViewsContext)
  const { numBookingsSelected } = useContext(BookingSelectionContext)

  const { selectedBookingIds } = useContext(BookingSelectionContext)
  const firstBooking = selectedBookingIds[0]
  const { showLoading: sidebarIsLoading } = useBookingById(firstBooking)
  return (
    <SidebarWithPage
      className="booking-detail"
      sidebarIsLoading={sidebarIsLoading}
      showSidebar={
        isVisibleSidebar("booking-detail") && numBookingsSelected > 0
      }
      showPage={isVisiblePage("booking-detail") && numBookingsSelected > 0}
      sidebarContent={<BookingDetailSidebar />}
      pageContent={<BookingDetailPage />}
    />
  )
}
