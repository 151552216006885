import { PropTypes } from "prop-types";
import PageHeader from "components/page-header/PageHeader";
import Back from "components/actions/Back";
import HeaderButton from "components/page-header/HeaderButton";
import FilterBar from "components/filter-bar/FilterBar";
import TaskCard from "../task-card/TaskCard";
import "./TasksView.css";
import Loading from "components/loading/Loading";
import Table from "components/table/Table";
import Card from "components/card/Card";
import { useState, useContext,useEffect,useMemo,useCallback } from "react";
import ReassignMultipleBookingsButton from "views/composable/buttons/ReassignMultipleBookingsButton";
import { BookingSelectionContext } from "../../../../context/BookingSelectionContext";
import { TripSelectionContext } from "../../../../context/TripSelectionContext";
import useTripById from "../../../../hooks/useTripById";
import { useHeaderFilters } from "hooks/useHeaderFilters";
import useBookings from "hooks/useBookings";
import { BookingIcon } from "components/icons/Icon";
import StatusLabel from "components/card/card-content/StatusLabel";

import {
  TableContextConsumer,
  TableContextProvider,
} from "context/TableContext";
import { GoogleMap, useLoadScript,DirectionsService,DirectionsRenderer } from "@react-google-maps/api";

const filterFields = [
  {
    field: "assignedTripSign",
    label: "Trip",
    fixed: true,
  },
];
const taskTableColumns = [
  {
    field: "booking",
    headerName: "Lead Name",
    flex: 3,
    renderCell: (params) => (
      <>
        {params.value.icon ?? <BookingIcon />}
        <div className="info">
          <div className="t-label">{params.value.label} PAX</div>
        </div>
      </>
    ),
    sortComparator: (value1, value2) =>
      value1.title.localeCompare(value2.title),
  },
  {
    field: "extBooking",
    headerName: "Booking",
    flex: 2,
  },
  {
    field: "tO",
    headerName: "T.O.",
    flex: 2,
  },
  { field: "flightNumber", headerName: "Flight (IATA)", flex: 2 },
  {
    field: "flightBookingTime",
    headerName: "Time",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => (
      <div>{params.value ? <StatusLabel text={params.value} /> : <></>}</div>
    ),
    flex: 2,
    headerClassName: "t-table-header_padded",
  },
];

export default function TasksView(props) {

  const { selectedDate } = useHeaderFilters();
  const { selectedTripId } = useContext(TripSelectionContext);
  const {
    data: { tasks, tO = [], sign } = { tasks: [], t0: [] },
    isLoading,
    isFetching,
  } = useTripById(selectedTripId);
  const { data: bookings, showLoading } = useBookings({
    tripId: selectedTripId,
  });
  const {
    selectedBookingIds,
    numBookingsSelected,
    setSelectedBookings,
    areAnyBookingsSelected,
    selectBooking,
  } = useContext(BookingSelectionContext);
  const onClickReassign = (event) => {
    event.stopPropagation();
    props.openReassignView(event);
  };
  const [selectionsByTable, setSelectionsByTable] = useState(new Map());
  const [response, setResponse] = useState(null);

  const origin = tasks.length > 0 ? { lat: tasks[0]?.lat, lng: tasks[0]?.lng } : null;
  const destination = tasks.length > 0 ? { lat: tasks[tasks.length - 1]?.lat, lng: tasks[tasks.length - 1]?.lng } : null;
  
  // Exclude the first and last task from waypoints (origin and destination)
  const waypoints = tasks
    .slice(1, tasks.length - 1) // Only include tasks between the origin and destination
    .map(task => ({ location: { lat: task.lat, lng: task.lng } }));

  const [directionsFormValue, setDirectionsFormValue] = useState({
    origin: '',
    destination: '',
    travelMode: '',
    waypoints: [],
  })

  const handleSelectionChange = (newSelection, tableId) => {
    selectionsByTable.set(tableId, newSelection);
    setSelectedBookings([].concat(...selectionsByTable.values()));
  };

  useEffect(() => {
    setDirectionsFormValue({
      origin: origin,
      destination: destination,
      travelMode: 'DRIVING',
      waypoints: waypoints,
    })

  }, []);


  useEffect(() => {
    if (selectedBookingIds.length === 0) setSelectionsByTable(new Map());
  }, [selectedBookingIds]);

  const onClickTableRow = (event, id) => {
    selectBooking(id);
    props.onClickBooking(event);
  };
  const onClickClose = () => {
    props.onClickClose();
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCp7xeh8l4ugmPUuT3L2c43pMPoEvu4qvw",   
  })

 

  const directionsCallback = useCallback(
    (
      result,
      status
    ) => {
      if (result !== null) {
        if (status === 'OK') {
          setResponse(result)
        } else {
          console.log('response: ', result)
        }
      }
    },
    []
  )

  const onMapClick = useCallback((e) => {
    console.log('onClick args: ', e)
  }, [])

  const directionsServiceOptions =
    useMemo(() => {
      return {
        destination: directionsFormValue.destination,
        origin: directionsFormValue.origin,
        travelMode: directionsFormValue.travelMode,
        waypoints: directionsFormValue.waypoints, // Pass waypoints
        optimizeWaypoints: true
      }
    }, [
      directionsFormValue.origin,
      directionsFormValue.destination,
      directionsFormValue.travelMode,
      directionsFormValue.waypoints,
    ])


  const directionsResult = useMemo(() => {
    return {
      directions: response,
    }
  }, [response])

  function renderTaskWithBookings(taskData) {
    // only show table if task has pickups
    const { bookings, id } = taskData;
    return (
      <Card key={id} className="task-card">
        {<TaskCard {...taskData} />}
        {bookings.length > 0 ? (
          <Table
            rows={bookings}
            columns={taskTableColumns}
            showActions={false}
            tableConfig={{ hideFooter: true }}
            enableSelect={true}
            loading={isFetching || showLoading}
            onSelectionChange={(selection) =>
              handleSelectionChange(selection, id)
            }
            selectionModel={selectionsByTable.get(id)}
            onDetailClick={onClickTableRow}
          />
        ) : (
          <div className="item task-card_no-bookings">
            <span className="t-label">No bookings to show</span>
          </div>
        )}
      </Card>
    );
  }
  return (
    <div className="tasks-view">
      <Back onClick={props.onClickBack} />
      <PageHeader title="Stops">
        <HeaderButton
          key="1"
          onClick={onClickClose}
          variant="light fill"
          text="close"
        />
        {areAnyBookingsSelected ? (
          <ReassignMultipleBookingsButton
            numBookingsSelected={numBookingsSelected}
            onClickReassign={onClickReassign}
          />
        ) : (
          <HeaderButton text="New Trip" onClick={props.openNewTrip} />
        )}
      </PageHeader>
      <TableContextProvider
        data={bookings}
        filterFields={filterFields}
        initialFilters={{ tO, assignedTripSign: [sign] }}
      >
        <TableContextConsumer>
          <FilterBar title={selectedDate.toDateString()} />
        </TableContextConsumer>
      </TableContextProvider>
      {isLoading ? (
        <Loading show={isLoading} />
      ) : (
        <div className="tasks-list">
          {tasks.map((item) => renderTaskWithBookings(item))}
        </div>
      )}

      <div>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <div>
            <GoogleMap
              mapContainerClassName="map-container"
              // center={{ lat: tasks[0]?.lat || 0, lng: tasks[0]?.lng || 0 }}
              zoom={10}
              onClick={onMapClick}
            >
             
                {directionsFormValue.destination !== '' &&
                directionsFormValue.origin !== '' && (
                  <DirectionsService
                    options={directionsServiceOptions}
                    callback={directionsCallback}
                  />
                )}

              {directionsResult.directions && (
                <DirectionsRenderer options={directionsResult} />
              )}
            </GoogleMap>
          </div>
        )}
      </div>
    </div>
  );
}


TasksView.propTypes = {
  onClickBack: PropTypes.func,
  onClickClose: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickBooking: PropTypes.func,
  closeBookingDetail: PropTypes.func,
  openReassignView: PropTypes.func.isRequired,
  openNewTrip: PropTypes.func,
  loading: PropTypes.bool,
};
