import { PropTypes } from "prop-types";
import "./StatusSelector.css"
import Button from "components/button/Button";

StatusSelector.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.number,
    })
  ),
  onChange: PropTypes.func,
};

export default function StatusSelector(props) {
  const onChange = (evt) => {  // !!!some bugs here
    const value = evt.target?.value;
    props.onChange(value);
  };

  const DEFAULT_EMPTY_NAME = "all"; //All

  const renderOptions = () => {
    return (props.options || []).map((x) => {
      return (
        <option key={x.value} value={x.value}>
          {x.value || DEFAULT_EMPTY_NAME}
        </option>
      );
    });
  }

  const getStatusLabel = () => {
    const v = (props.options ?? []).find(
      (x) => x?.value === props.value
    );
    return v? v.value || DEFAULT_EMPTY_NAME: "returning all jobs";
  }

  return (
    <Button
      variant="default outline"
      className="mobi-tui-rqjobstatus-selector"
      ariaLabel="rqjobstatus-selector"
    >
      <div className="mobi-tui-rqjobstatus-selector__btn-content">
        <label>
          <strong>{getStatusLabel()}</strong>
          <select
            value={props.value}
            onChange={onChange}
            className="mobi-tui-rqjobstatus-selector__select-css"
          >
            {renderOptions()}
          </select>
        </label>
        <div className="mobi-tui-rqjobstatus-selector__carret"></div>
      </div>
    </Button>

  );


};