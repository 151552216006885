import { ToggleButton } from "@material-ui/lab";
import { TuiToggleButtonGroup } from "components/toggle-button/TuiToggle";
import { useHeaderFilters } from "hooks/useHeaderFilters";
export default function HeaderDirectionToggle(props) {
  const { selectedDirection, setDirection } = useHeaderFilters();
  const handleDirectionToggleChange = (_e, value) => {
    setDirection(value);
  };
  return (
    <TuiToggleButtonGroup
      onChange={handleDirectionToggleChange}
      value={selectedDirection}
    >
      <ToggleButton value="from airport">Arrivals</ToggleButton>
      <ToggleButton value="to airport">Departures</ToggleButton>
    </TuiToggleButtonGroup>
  );
}
