import './CardDataItem.css'
import { PropTypes } from 'prop-types';
CardDataItem.propTypes = {
    field: PropTypes.string,
    value: PropTypes.string
}
export default function CardDataItem(props){
    return <div className="item card-data-item">
        <span className='t-label'>{props.field}</span>
        <span className='t-title xxs'>{props.value}</span>
    </div>
}