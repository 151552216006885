import { createContext, useState } from "react";
require("lodash");
export const RQjobSelectionContext = createContext({
  selectedRQjobIds: [],
});
export function RQjobSelectionProvider(props) {
  const [selectedRQjobIds, setSelectedRQjobIds] = useState([]);
  const selectRQjob = (jobId) => {
    setSelectedRQjobIds([jobId]);
  };

  const clearSelectedRQjobs = () => setSelectedRQjobIds([]);
  const areAnyRQjobsSelected = selectedRQjobIds.length > 0;
  const areMultipleRQjobIdsSelected = selectedRQjobIds.length > 1;
  const numRQjobsSelected = selectedRQjobIds.length;
  return (
    <RQjobSelectionContext.Provider
      value={{
        selectedRQjobIds,
        selectRQjob,
        setSelectedRQjobs: setSelectedRQjobIds,
        areAnyRQjobsSelected,
        clearSelectedRQjobs,
        numRQjobsSelected,
        areMultipleRQjobIdsSelected,
      }}
    >
      {props.children}
    </RQjobSelectionContext.Provider>
  );
}
export default RQjobSelectionContext;
