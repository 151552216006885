import { dropdownShape } from "../../util/types";
import { Fragment, useState } from "react";
import { PropTypes } from "prop-types";
import "./FilterBar.css";
import MultiSelect from "../multi-select/MultiSelect";

import SearchBox from "../search-box";

const NO_SELECTION = Symbol();

const FilterBar = ({
  items = [],
  title,
  currentFilters,
  onFilterChange,
  withSearch = false,
  requestSearch,
  rowsLength,
}) => {
  const [currentlySelected, setCurrentlySelected] = useState(NO_SELECTION);

  const clearHandler = (option, items) => {
    const filteredArray = currentFilters[items.category].filter(
      (_item) => _item !== option
    );

    onFilterChange({
      ...currentFilters,
      [items.category]: filteredArray,
    });
  };

  const onChangeHandler = (event, item) => {
    setCurrentlySelected(item.category);
    let arrSelected = event.target.value.reduce((acc, cval) => {
      return [...acc, cval];
    }, []);

    if (event.target.value.length < 1) {
      onFilterChange({
        ...currentFilters,
        [item.category]: event.target.value,
      });
    } else {
      onFilterChange({
        ...currentFilters,
        [item.category]: arrSelected,
      });
    }
  };

  // const placeholder = isSearching || `Search ${rowsLength} Results...`;
  const placeholder = `Search ${rowsLength} Results...`;

  return (
    <div className="mobi-tui-filterbar mb-l">
      <div className="label t-label">{title ? title : "Filter By"}</div>

      <div className="filter-container" role="toolbar">
        {items.map((item) => {
          return (
            <Fragment key={item.label}>
              <MultiSelect
                id={item.key}
                key={item.label}
                label={item.label}
                options={item.options}
                selected={item.category === currentlySelected}
                onChange={(event) => {
                  onChangeHandler(event, item);
                }}
                clearHandler={(event) => {
                  clearHandler(event, item);
                }}
                values={currentFilters[item.category]}
                fixed={item.fixed ?? false}
              />
            </Fragment>
          );
        })}
      </div>
      {withSearch ? (
        <div className="search-bar" key="search-bar">
          <SearchBox
            placeholder={placeholder}
            onChange={({ currentTarget }) => requestSearch(currentTarget.value)}
          />
        </div>
      ) : null}
    </div>
  );
};

FilterBar.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(dropdownShape)),
  onFilterChange: PropTypes.func,
  currentFilters: PropTypes.any,
};
export default FilterBar;
