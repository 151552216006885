import { filterCategories } from "../../util/trips";
import { useHeaderFilters } from "hooks/useHeaderFilters";
import { useContext, useEffect } from "react";
import { TripSelectionContext } from "../../context/TripSelectionContext";
import Notification from "views/header/Notification";
import useNotifications from "../../hooks/useNotifications";
import { ViewNames, ViewsContext } from "../../context/ViewsContext";
import { TableContextProvider, TableContext } from "context/TableContext";
import { useTrips } from "../../hooks/useTrips";
import { tableColumns } from "util/trips";
import PageHeader from "components/page-header/PageHeader";
import Table from "components/table/Table";
import FilterBar from "components/filter-bar/FilterBar";
import "./TripsView.css";
import HeaderButton from "components/page-header/HeaderButton";
import HeaderDirectionToggle from "views/header/HeaderDirectionToggle";
import { TableContextConsumer } from "../../context/TableContext";
import { HeaderDatePicker } from "views/header/HeaderDatePicker";
import { HeaderLocationPicker } from "views/header/HeaderLocationPicker";
import usePlanTrips from "hooks/usePlanTrips"
import { SnackbarContext } from "context/SnackbarContext"

function TripsView(props) {
  const { selectedDestination, selectedDate, selectedDirection } =
    useHeaderFilters();
  const { onFilterChange: setFilters } = useContext(TableContext);
  useEffect(
    () => setFilters({}),
    [selectedDestination, selectedDate, selectedDirection]
  );

  const { data: trips, showLoading, } = useTrips();

  const { openView, closeAllViews } = useContext(ViewsContext);

  const { unfeasibleTripsMessage } = useNotifications();
  const { planTrips, isLoading } = usePlanTrips();
  const { openSnackbar } = useContext(SnackbarContext)
  const handleSuccess = async () => {
    openSnackbar({ message: "success", severity: "success" })
  }

  

  const totalCostWithCurrency = trips
  ? trips.reduce(
      (acc, trip) => {
        const cost = trip.cost?.amount || 0;
        const currencyId = trip.cost?.currency_id || acc.currency_id;
        return {
          sum: acc.sum + cost,
          currency_id: currencyId, // Preserve the first non-null currency_id found
        };
      },
      { sum: 0, currency_id: null } // Initialize with 0 cost and null currency_id
    )
  : { sum: 0, currency_id: null };


  const { sum: totalCost, currency_id } = totalCostWithCurrency;

  const formattedNumber = new Intl.NumberFormat('el-GR', {
    style: 'currency',
    currency: currency_id || 'EUR',
    currencyDisplay: 'symbol', 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(totalCost || 0);

  const viewUnfeasibleTrips = () => {
    closeAllViews();
    setFilters({
      feasible: ["Unfeasible"],
    });
  };

  const onClickNewTrip = (e) => {
    e.stopPropagation();
    openView({
      viewName: ViewNames.newTrip,
      showPage: true,
      showSidebar: true,
    });
  };

  const onClickPlanTrips = async (e) => {
    try {
      await planTrips({ force: true })
      handleSuccess()
    } catch (e) {
      console.error(e)
      openSnackbar({ message: "error", severity: "error" })
    }
  }

  const { setTripId } = useContext(TripSelectionContext);
  const handleSelectTrip = (e, id) => {
    e.stopPropagation();
    props.onNavigate();
    setTripId(id);
    openView({ viewName: "trip-detail", showSidebar: true, showPage: false });
  };
  const onExitDetail = (e) => {
    e.stopPropagation();
    closeAllViews();
  };

  return (
    <div className="trips-view">
      <Notification
        open={props.showNotif}
        message={unfeasibleTripsMessage}
        onClickAction={viewUnfeasibleTrips}
        buttonVariant="negative outline"
      />
      <div className="trips-list">
        <div className="mobi-tui-pagewrap" onClick={onExitDetail}>
          <PageHeader title="All Trips" subtitle="Trips">
            <HeaderDirectionToggle />
            <HeaderLocationPicker />
            <HeaderDatePicker  />
            <HeaderButton text="New Trip" onClick={onClickNewTrip} />
            <HeaderButton text="Plan Trips" onClick={onClickPlanTrips} />
          </PageHeader>
          <TableContextConsumer>
            <FilterBar withSearch={true} />
            <Table
              columns={tableColumns}
              showActions={false}
              enableSelect={false}
              onDetailClick={handleSelectTrip}
              loading={props.showLoading | isLoading}
            />
          
                  
          </TableContextConsumer>
          {totalCost > 0 && 
            <div className="trips-view-footer">  
                <div className="trips-total-cost">
                  Total Cost: {formattedNumber}
                </div>
            </div>}
        </div>
      </div>
    </div>
  );
}

function WithContext(props) {
  const { data, showLoading } = useTrips();
  return (
    <TableContextProvider filterFields={filterCategories} data={data}>
      <TripsView {...props} showLoading={showLoading} />
    </TableContextProvider>
  );
}
export { WithContext as TripsView };
