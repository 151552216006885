import { useMutation, useQueryClient } from "react-query"
import { useApi } from "./api"
import { useHeaderFilters } from "./useHeaderFilters"
export default function useAssignBookings() {
  const { post } = useApi()
  const postAssign = async ({ bookingIds, tripId, force }) => {
    await post(`trips/${tripId}/bulk_assign_bookings`, {
      bookings: JSON.stringify(bookingIds),
      force: force
    })
    return { bookingIds, tripId }
  }
  const { selectedDestination, selectedDate, selectedDirection } =
    useHeaderFilters()
  const onAssignDone = ({ bookingIds, tripId }) => {
    for (const booking of bookingIds) {
      queryClient.setQueryData(["booking", booking], (oldData) => ({
        ...oldData,
        trip: tripId,
      }))
    }
    queryClient.refetchQueries([
      "trips",
      { selectedDestination, selectedDate, selectedDirection },
    ])
    queryClient.refetchQueries([
      "bookings",
      { selectedDestination, selectedDate, selectedDirection },
    ])
  }
  const queryClient = useQueryClient()
  const {
    mutateAsync: assignBookings,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(postAssign, {
    mutationKey: "assignBookings",
    onSuccess: onAssignDone,
  })
  return { assignBookings, isError, isLoading, isSuccess }
}
