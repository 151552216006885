import { PropTypes } from 'prop-types';
import './Back.css'
Back.propTypes = {
    onClick:PropTypes.func
}
export default function Back(props){
    // todo: add arrow
    return <div className = 'back-button t-label' onClick={props.onClick}>
        Back
    </div>
}