import HeaderButton from "../../../components/page-header/HeaderButton";
import { PropTypes } from "prop-types";
InsertMultipleBookingsButton.propTypes = {
  numBookingsSelected: PropTypes.number,
  onClickInsert: PropTypes.func,
};
export default function InsertMultipleBookingsButton(props) {
  return (
    <HeaderButton
      text={`Insert ${props.numBookingsSelected} Bookings`}
      onClick={props.onClickInsert}
    />);
}
