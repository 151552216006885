import "./SidebarWithPage.css";
import { PropTypes } from "prop-types";
import Sidebar from "../../../components/sidebar/Sidebar";
import Page from "../../../components/page/Page";
SidebarWithPage.propTypes = {
  showSidebar: PropTypes.bool,
  showPage: PropTypes.bool,
  sidebarIsLoading: PropTypes.bool,
  sidebarContent: PropTypes.any,
  pageContent: PropTypes.any,
  onClickPage: PropTypes.func,
  className: PropTypes.string,
};
export default function SidebarWithPage(props) {
  return (
    <div className={`sidebar-with-page ${props.className || ""}`}>
      <div
        className={props.showPage ? "viewTripDetail show-page" : "hide-page"}
      >
        <div
          className={
            props.showSidebar || props.showPage ? "viewSidebar open" : ""
          }
        >
          <div className="trip-detail-container">
            <Sidebar loading={props.sidebarIsLoading} show={props.showSidebar}>
              {props.sidebarContent}
            </Sidebar>
            <Page onClick={props.onClickPage} show={props.showPage}>
              {props.showPage ? props.pageContent : <></>}
            </Page>
          </div>
        </div>
      </div>
    </div>
  );
}
