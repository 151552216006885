//import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
const baseURL =
  "https://aitrips-be.cyberlogic.cloud/";
const username = "root";
const password = "SONYA1scraped0reward_roger";

axios.defaults.baseURL = baseURL
// function getApiKey() {
//   if (process.env.REACT_APP_API_KEY) {
//     return process.env.REACT_APP_API_KEY
//   }
//   return localStorage.getItem("x-api-key")
// }

export const useApi = () => {
  const getHeaders = async () => {    
    var credentials = btoa(username + ":" + password);
    var basicAuth = "Basic " + credentials;

    const headers = {};
    headers.Authorization = basicAuth;
    return {
      headers,
    };
  };

  const get = async (path, params = {}) => {
    try {
      const { headers } = await getHeaders()
      const res = await axios.get(`${path}`, { headers, params })
      const data = res.data
      return data
    } catch (e) {
      throw e
    }
  }
  const post = async (path, body, params = {}) => {
    let formData = new FormData()
    for (const field in body) {
      formData.append(field, body[field])
    }

    try {
      const { headers } = await getHeaders()
      const request = axios.post(path, formData, {
        headers,
        params,
      })
      const res = await request
      const json = res.data
      return json
    } catch (e) {
      // handle error if required
      console.error(e)
      throw e
    }
  }
  const patch = async (path, body, params = {}) => {
    let formData = new FormData()
    for (const field in body) {
      formData.append(field, body[field])
    }

    try {
      const { headers } = await getHeaders()
      const request = axios.patch(path, formData, {
        headers,
        params,
      })
      const res = await request
      const json = res.data
      return json
    } catch (e) {
      // handle error if required
      console.error(e)
      throw e
    }
  }
  return {
    get,
    post,
    patch,
  }
}
