import { formatDatetimeForUi } from "./util";
export const BookingStatus = Object.freeze({
  BEFORE_START: "Before Start",
  BOARDED_BUS: "Boarded",
  DROPPED_OFF: "Dropped Off",
});
export function getRQjobsViewDataForUi(dataFromApi = []) {
  function toRQjobsListItem(item = {}) {
    return {
      // arrival: item.arrival,
      // key: item.id,
      id: item.id,
      created: formatDatetimeForUi(item.created),
      enqueued: formatDatetimeForUi(item.enqueued),
      started: formatDatetimeForUi(item.started),
      ended: formatDatetimeForUi(item.ended),
      status: item.status,
    };
  }
  return dataFromApi.map(toRQjobsListItem);
}
export function getRQjobDetailDataForUi(dataFromApi) {
  const {
    id,
    created,
    enqueued,
    started,
    ended,
    status,
  } = dataFromApi;
  const dataForUi = {
    id,
    created,
    enqueued,
    started,
    ended,
    status,
  };
  return dataForUi;
}

export const emptyRQjobDetail = {
  id: "",
  created: "",
  enqueued: "",
  started: "",
  ended: "",
  status: "finished",
};


export const tableColumns = [
  {
    field: "id",
    headerName: "ID",
    flex: 10,
  },
  {
    field: "created",
    headerName: "CREATED",
    flex: 3,
  },
  { field: "enqueued", headerName: "ENQUEUED", flex: 3, },
  { field: "started", headerName: "STARTED", flex: 3, },
  { field: "ended", headerName: "ENDED", flex: 3, },
  {
    field: "status",
    headerName: "STATUS",
    flex: 7,
  },
  
];
