//ToDo

// Django dashboard
// ***:8080/tui-cps/v1/destinations/

import PropTypes from "prop-types";
import {useCallback, useState} from "react";
import {makeDateForAPI} from "../../hooks/useHeaderFilters";
import Button from "../../components/button/Button";
import {useApi} from "../../hooks/api";

const insightTextPlaceholder = "Insights..."

const RequestInsightsTokenButton = () => {
  const destIdElement = document.getElementById("destId")
  const startDateElement = document.getElementById("startDate")
  const endDateElement = document.getElementById("endDate")
  const tokenIdElement = document.getElementById("tokenId")
  const insightsElement = document.getElementById("insightText")


  let [isSending, setIsSending] = useState(false)
  const {post} = useApi()

  const sendRequest = useCallback(async () => {
    const destId = destIdElement.value
    const startDateStr = startDateElement.value
    const startDate = new Date(startDateStr);
    const endDateStr = endDateElement.value
    const endDate = new Date(endDateStr);
    if (isSending) return

    if (destId == null || startDate == null || endDate == null || destId === "" || startDate === "" || endDate === "") {
        window.alert(`Please enter date range and destination id`)
        return
    }

    // don't send again while we are sending
    // update state
    setIsSending(true)
    // send the actual request
    const res = await post(`generate_insights/?destination=${destId}&date_from=${startDateStr}&date_to=${endDateStr}`, {})
    const successSubString = "Success! File retrieval key is: "
    console.log(res) // the reload is complete
    if (!res.startsWith(successSubString)) {
        window.alert(`Response from CPS: ${res} for ${destId} range date_from=${makeDateForAPI(startDate)} date_to=${makeDateForAPI(endDate)}`)
        tokenIdElement.value = ""
    }
    else {
        tokenIdElement.value = res.substring(successSubString.length)
    }
    insightsElement.value = insightTextPlaceholder
    // once the request is sent, update state again
    setIsSending(false)
  }, [startDateElement, endDateElement, destIdElement, tokenIdElement, isSending]) // update the callback if the state changes
  return <Button loading={isSending} onClick={sendRequest} style={{width: '60px'}}>Request Insights Token </Button>
};

const RequestInsightsButton = () => {
  const tokenIdElement = document.getElementById("tokenId")
  const insightsElement = document.getElementById("insightText")


  let [isSending, setIsSending] = useState(false)
  const {get} = useApi()

  const sendRequest = useCallback(async () => {

    const tokenId = tokenIdElement.value
    if (isSending) return
    if (tokenId == null && tokenId === '') {
        return
    }
    // update state
    setIsSending(true)
    // send the actual request
    try {
      const res = await get(`retrieve_insights/?insights_id=${tokenId}`, {})
      const potential_savings = res["Potential Savings"]
      const rules_suggestions = res["Rules Suggestions"]
      let rules_suggestions_str = "None"
      if (rules_suggestions) rules_suggestions_str = rules_suggestions.join(" ... ")
      insightsElement.value =  `${potential_savings}:   ${rules_suggestions_str}`
      console.log(res) // the reload is complete

    } catch (e){
      window.alert(`Response from CPS: ${e.response.data}`)
      insightsElement.value = insightTextPlaceholder
      console.error(e)
    }

    setIsSending(false)
  }, [isSending, tokenIdElement]) // update the callback if the state changes
  return <Button loading={isSending} onClick={sendRequest} style={{width: '60px'}}>Request Insights </Button>
};

RequestInsightsTokenButton.propTypes = {children: PropTypes.node};
RequestInsightsButton.propTypes = {children: PropTypes.node};

const InsightsView = (props) => {


  /* todo - rename trips-list */
  return (
      <div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <ul>

              <li>
                  <label htmlFor="startDate">Start:</label>
                  <input type="date" id="startDate" name="start_date"/>
              </li>
              <br/>
              <li>
                  <label htmlFor="endDate">End:</label>
                  <input type="date" id="endDate" name="end_date"/>
              </li>
              <br/>
              <li>
                  <label htmlFor="destId">Destination ID:</label>
                  <input type="text" id="destId" name="destination_id"/>
              </li>
              <br/>
              <br/>
              <RequestInsightsTokenButton/>
              <br/>
              <br/>
              <br/>
              <br/>
              <li>
                  <label htmlFor="tokenId">Token ID:</label>
                  <input type="text" id="tokenId" size="40" name="token_id"/>
              </li>
              <br/>
              <RequestInsightsButton/>
              <br/>
              <br/>
              <br/>
              <textarea id="insightText" name="insightText" rows="5" cols="60" wrap="hard" readOnly="true">
Insights...
                </textarea>


          </ul>

      </div>
  );
};


InsightsView.propTypes = {
    showNotif: PropTypes.bool,
};
const InsightsViewWrapped = (props) => {
    return (
        <InsightsView {...props} />
    );
};


export {InsightsViewWrapped as InsightsView};
