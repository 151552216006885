import { createContext, useState } from "react"
export const TripSelectionContext = createContext({
  selectedTripId: "",
})
export function TripSelectionProvider(props) {
  const [selectedTripId, setTripId] = useState()
  return (
    <TripSelectionContext.Provider
      value={{
        selectedTripId,
        setTripId,
      }}
    >
      {props.children}
    </TripSelectionContext.Provider>
  )
}
export default TripSelectionContext
