//ToDo

// Django dashboard
// ***:8080/tui-cps/v1/django-rq/

import PageHeader from "components/page-header/PageHeader";
import PropTypes from "prop-types";
import { useContext } from "react";
import { RQjobSelectionContext } from "../../context/RQjobSelectionContext";
import { ViewsContext } from "../../context/ViewsContext";
import useRQjobs from "hooks/useRQjobs";
import { tableColumns } from "../../util/rqjobs";
import {
  TableContextProvider,
  TableContextConsumer,
} from "context/TableContext";
import FilterBar from "components/filter-bar/FilterBar";
import Table from "components/table/Table";
import { HeaderStatusPicker } from "views/header/HeaderStatusPicker"; 


const RQjobsView = (props) => {
  const {
    setSelectedRQjobs,
    selectedRQjobsIds,
  } = useContext(RQjobSelectionContext);
  const { openView, closeAllViews } = useContext(ViewsContext);
  const { showLoading } = useRQjobs();
  const onClickRow = (event, id) => {
    event.stopPropagation();
    setSelectedRQjobs([id]);
    props.onNavigate();
    openView({
      viewName: "RQjobs-detail",
      showSidebar: true,
      showPage: false,
    });
  };


  return (
    <div>
      <div className="trips-list">
        <div className="mobi-tui-pagewrap" onClick={closeAllViews}>
          <PageHeader title="All Planning Jobs" subtitle="planning jobs">
            <HeaderStatusPicker />
          </PageHeader>

          <TableContextConsumer>
            <FilterBar withSearch={true} />
            <Table
              columns={tableColumns}
              showActions={false}
              enableSelect={false}  // no checkbox for now
              onDetailClick={onClickRow}
              onSelectionChange={setSelectedRQjobs}
              loading={showLoading}
              selectionModel={selectedRQjobsIds}
            />
          </TableContextConsumer>
        </div>
      </div>
    </div>
  );
};

RQjobsView.propTypes = {
  showNotif: PropTypes.bool,
};
const RQjobsViewWrapped = (props) => {
  const { data } = useRQjobs();
  return (
    <TableContextProvider data={data}>
      <RQjobsView {...props} />
    </TableContextProvider>
  );
};

export { RQjobsViewWrapped as RQjobsView };
