import {
  capitalizeFirstLetter,
  formatTimeForUi, formatTimeRangeForUi,
  formatTimeWindowsForUi,
} from "./util";
import {
  BusStatusIcon,
  MinibusStatusIcon,
  TaxiStatusIcon,
} from "../components/icons/VehicleIcons";
import { FlightIcon, HotelIcon } from "components/icons/Icon";
import Badge from "../components/badge/Badge";
import { BabyIconTransparent, BookingIcon } from "components/icons/Icon";
import { getBookingsViewDataForUi } from "./bookings";
export const DEFAULT_TRIP_ID = "a696919b-3dd8-4d0d-85df-674f312a4e2a";
export const TripStatus = Object.freeze({
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  BEFORE_START: "Before Start",
});
export const tripStatusLabel = {
  [TripStatus.IN_PROGRESS]: "In Progress",
  [TripStatus.COMPLETED]: "Completed",
  [TripStatus.BEFORE_START]: "Before Start",
};
export const tripStatusColor = {
  [TripStatus.IN_PROGRESS]: "yellow",
  [TripStatus.COMPLETED]: "green",
  [TripStatus.BEFORE_START]: "gray",
};

export function parseTrip(trip = {}) {
  const {
    id,
    status: tripStatus = "Before Start",
    feasible,
    areas = [],
    infant = false,
  } = trip;
  const { vehicle } = trip ?? {};
  const { type: vehicleType } = vehicle ?? {};
  const status = TripStatus[tripStatus];

  const IconForVehicleOnly =
    {
      Bus: BusStatusIcon,
      Minibus: MinibusStatusIcon,
      Taxi: TaxiStatusIcon,
    }[vehicleType] ?? BusStatusIcon;
  const icon = (
    <Badge hide={feasible}>
      <IconForVehicleOnly color={tripStatusColor[status]} />
    </Badge>
  );
  const iconGray = <IconForVehicleOnly color="gray" />;

  return {
    ...trip,
    infant,
    icon,
    iconGray,
    id,
    sign: trip.sign,
    vehicleType,
    direction: trip.to_airport ? "to airport" : "from airport",
    destination: capitalizeFirstLetter(trip.destination?.toString()),
    destinations: trip.to_airport ? trip.start_area : trip.end_area,
    areas,
    tO: trip.tour_operators?.map((item) => item.name),
    tOString: trip.tour_operators?.map((item) => item.name).join(", "),
    flight: trip.flights?.map(
      (item) => `${item.code} (${item.home_airport ?? "Unknown"})`
    ),
    iataAirport: trip.iataAirport,
    supplier: trip.vehicle?.supplier,
    service: trip.type,
    passengers: String(trip.seated_pax),
    availableSeats: trip.available_seats ? trip.available_seats : 0,
    feasible: trip.feasible ? "Feasible" : "Unfeasible",
    status,
    hasInfants: infant ? "yes" : "no",
    driver: trip.driver_name,
    vehicle: trip.vehicle_name,
    statusText: tripStatusLabel[status],
    numFlights: trip.flights?.length,
    flightTime: formatTimeRangeForUi(trip.earliest_flight, trip.latest_flight),
    flightWindows: formatTimeWindowsForUi(
      trip.earliest_flight,
      trip.latest_flight
    ),
    hotel: trip.hotels,
    title:
      capitalizeFirstLetter(trip.destination?.toString()) +
      " #" +
      String(trip.sign) +
      "",
    taskLabel: trip.to_airport ? "to airport" : "from airport",
    location: trip.start_location,
    trip: {
      title:
        capitalizeFirstLetter(trip.destination) + " #" + String(trip.sign) + "",
      taskLabel: trip.to_airport ? "to airport" : "from airport",
      sign: trip.sign,
      icon: icon,
    },
    displayFeasibilityInRed: !trip.feasible,
    tripCardTitle: "Trip #" + trip.sign?.toString(),
    departureTime: formatTimeForUi(trip.start_time),
    // status: tripStatusLabel[TripStatus[selectedTrip.status]],
  };
}

export function toTripListData(tripData) {
  return tripData.map(parseTrip).map((trip) => ({
    ...trip,
  }));
}

export function parseTripDetail(data) {
  const {
    tasks = [],
    sign,
    trip_remarks: remarks = "",
    id,
    infant,
    to_airport: toAirport,
  } = data;
  const fromAirport = !toAirport;
  function toTaskDataForUi(taskData) {
    return taskData.map((item) => {
      const {
        area,
        location,
        id,
        start_time,
        stop_number,
        pax_sum,
        pickup,
        booking_dropoff = [],
        booking_pickup = [],
      } = item;
      const taskType = pickup ? "Pickup" : "Dropoff";
      const stopTime = formatTimeForUi(start_time);

      let locationType;
      if (
        (toAirport && taskType === "Dropoff") ||
        (fromAirport && taskType === "Pickup")
      )
        locationType = "airport";
      else locationType = "hotel";

      let taskLabel;
      if (taskType === "Pickup") {
        if (locationType === "airport") {
          taskLabel = `Presentation at ${stopTime}`;
        } else taskLabel = `Pickup at ${stopTime}`;
      } else if (taskType === "Dropoff") {
        taskLabel = `Dropoff at ${stopTime}`;
      }
      const paxLabel = (pax_sum || 0).toString() + " Passengers";
      const bookings = toAirport
        ? getBookingsViewDataForUi(booking_pickup)
        : getBookingsViewDataForUi(booking_dropoff);
      let locationName, icon;
      if (locationType === "airport") {
        locationName = location.name;
        icon = <FlightIcon />;
      } else if (locationType === "hotel") {
        locationName = bookings[0].hotel;
        icon = <HotelIcon />;
      }
      return {
        id,
        location: locationName,
        lat: location.lat,
        lng: location.lng,
        icon,
        taskLabel,
        taskType,
        locationType,
        title: `${area ?? "No Area"}`,
        destIcon: <BookingIcon />,
        stopNumber: stop_number,
        paxLabel,
        bookings,
      };
    });
  }
  const detailData = {
    ...parseTrip(data),
    sign,
    infant,
    remarks,
    id,
    // exclude airport from task count
    numTasks: tasks.length - 1,
    title: `Trip #${data.sign}`,
    taskLabel:
      data.date +
      " : " +
      formatTimeForUi(data.start_time) +
      " to " +
      formatTimeForUi(data.end_time),
    busCode: data.vehicle_name || "No Vehicle",
    tasks: toTaskDataForUi(tasks),
  };
  return detailData;
}

export const emptyTripDetail = {
  numPassengers: 0,
  numTasks: 0,
  remarks: "",
};
export const tableColumns = [
  {
    field: "trip",
    headerName: "Trip",
    flex: 3,
    renderCell: (params) => (
      <>
        {params.value.icon}
        <div>{params.value.sign}</div>
      </>
    ),
    valueGetter: (params) => params.value,
    sortComparator: (value1, value2) => value1.sign - value2.sign,
  },
  {
    field: "areas",
    headerName: "Areas",
    flex: 6,
    valueGetter: (params) => params.value.join(", "),
  },
  {
    field: "tOString",
    headerName: "T.O.",

    flex: 6,
  },
  { field: "service", headerName: "Service", flex: 3 },
  {
    field: "flight",
    headerName: "Flight (IATA)",
    flex: 4,
    valueGetter: (params) => params.value.join(" "),
  },
  { field: "departureTime", headerName: "Start Time", width: 80, flex: 3 },
  {
    field: "passengers",
    headerName: "Pax",
    flex: 2.5,
    valueGetter: (params) => {
      return Number(params.value);
    },
  },
  { field: "availableSeats", headerName: "Avail.", width: 40, flex: 2.5 },
  {
    field: "cost",
    headerName: "Cost",
    flex: 2,
    valueGetter: (params) =>{
      const formattedNumber = new Intl.NumberFormat('el-GR', {
        style: 'currency',
        currency: params.value?.currency_id || 'EUR',
        currencyDisplay: 'symbol', 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(params.value?.amount || 0);

      return `${formattedNumber}`;
    },
  },
  {
    field: "infant",
    description: "Infants",
    flex: 2,
    headerClassName: "t-table-header_icon",
    cellClassName: "t-table-cell_flex",
    renderCell: (params) => {
      if (params.value) {
        return (
          <div className="cell">
            <BabyIconTransparent />
          </div>
        );
      } else return <></>;
    },
    valueGetter: (params) => params.value,
    sortComparator: (v1, v2) => (v1 === v2 ? 0 : v1 ? -1 : 1),
    renderHeader: () => <BabyIconTransparent />,
  },

  
];

export const filterCategories = [
  { field: "flightWindows", label: "Time" },
  { field: "flight", label: "Flight" },
  { field: "hotel", label: "Hotel" },
  { field: "service", label: "Service" },
  { field: "vehicleType", label: "Vehicle Type" },
  { field: "tO", label: "T.O." },
  { field: "areas", label: "Area" },
  { field: "supplier", label: "Supplier" },
  { field: "feasible", label: "Feasibility" },
];
