import { useMutation, useQueryClient } from "react-query"
import { useApi } from "./api"
import useHeaderFilters from "hooks/useHeaderFilters"
export default function useUnassignBookings() {
  const queryClient = useQueryClient()
  const { post } = useApi()
  const postUnassign = async (bookings) => {
    await post(`bookings/bulk_unassign`, {}, { id__in: bookings.join(",") })
    return bookings
  }
  const { selectedDestination, selectedDate, selectedDirection } =
    useHeaderFilters()
  const onUnassignDone = async (bookings) => {
    for (const booking of bookings) {
      queryClient.setQueryData(["booking", booking], (oldData) => ({
        ...oldData,
        trip: undefined,
      }))
    }
    queryClient.refetchQueries([
      "trips",
      { selectedDestination, selectedDate, selectedDirection },
    ])
    queryClient.refetchQueries([
      "bookings",
      { selectedDestination, selectedDate, selectedDirection },
    ])
  }
  const {
    mutateAsync: unassignBookings,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(postUnassign, {
    mutationKey: "unassignBookings",
    onSuccess: onUnassignDone,
  })
  return { unassignBookings, isError, isLoading, isSuccess }
}
