import { ToggleButtonGroup } from "@material-ui/lab";
import { PropTypes } from "prop-types";
import "./TuiToggle.css";
TuiToggleButtonGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};
export function TuiToggleButtonGroup(props) {
  return (
    <ToggleButtonGroup
      className="tui-toggle"
      role="toggle"
      exclusive={true}
      orientation="horizontal"
      onChange={props.onChange}
      value={props.value}
    >
      {props.children}
    </ToggleButtonGroup>
  );
}
