import { useState } from "react";
import omit from "lodash/omit";
import "react-dates/lib/css/_datepicker.css";
import "./datepicker.css";
import { usePopperTooltip } from "react-popper-tooltip";
import {
  DayPickerSingleDateController,
} from "react-dates-fns";
import {
  HORIZONTAL_ORIENTATION,
} from "react-dates/constants";

import { months } from "../../util/util";

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  initialDate: null,
  showInput: false,

  // day presentation and interaction related props
  // allowUnselect: false,
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange: (day) => false,
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  // renderNavPrevButton: null,
  // renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: "MMMM yyyy",
  hideKeyboardShortcutsPanel: true,

  onChange() {},
  renderTrigger() {},
};

export default function Datepicker(props) {
  const allProps = { ...defaultProps, ...props };
  const { renderTrigger } = allProps;

  const [focusedInput, setFocusedInput] = useState({ focused: true });
  const [startDate, setStartDate] = useState(allProps.initialDate);

  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const onOutsideClick = () => close();

  const onFocusChange = (focus) => {
    if (!focus.focused) {
      close();
    }
    setFocusedInput(focus);
  };

  const onDatesChange = (date) => {
    setStartDate(date);
    allProps.onChange(date);
  };

  const childProps = omit(allProps, [
    "autoFocus",
    "autoFocusEndDate",
    "initialDate",
    "renderTrigger",
    "onChange",
    "showInput",
    "allowUnselect",
  ]);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      trigger: "click",
      closeOnOutsideClick: false,
      visible: isOpen,
    });

  const initialVisibleMonth = () => {
    return startDate || new Date();
  };

  const availableMonths = months();
  const year = new Date().getFullYear();

  const returnYears = () => {
    const years = [];
    for (let i = year - 5; i <= year; i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginRight: "10px", position: "relative" }}>
          {availableMonths[month.getMonth()]}
          <select
            className="mobi-tui-location-selector__select-css"
            value={month.getMonth()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {availableMonths.map((label, value) => (
              <option value={value}>{label}</option>
            ))}
          </select>
        </div>
        <div style={{ position: "relative" }}>
          {month.getFullYear()}
          <select
            className="mobi-tui-location-selector__select-css"
            value={month.getFullYear()}
            onChange={(e) => onYearSelect(month, e.target.value)}
          >
            {returnYears()}
          </select>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        ref={setTriggerRef}
        style={{ display: "inline-block" }}
        onClick={open}
      >
        {renderTrigger(startDate)}
      </div>
      {isOpen && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          <DayPickerSingleDateController
            {...childProps}
            onDateChange={onDatesChange}
            onFocusChange={onFocusChange}
            onOutsideClick={onOutsideClick}
            focused={focusedInput.focused}
            date={startDate}
            renderMonthElement={renderMonthElement}
            initialVisibleMonth={initialVisibleMonth}
          />
        </div>
      )}
    </div>
  );
}
