export const SearchIcon = (props) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.5">
      <path
        d="M6.6001 6.6001L9.0001 9.0001"
        stroke="#122A5B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="3.9" cy="3.9" r="2.9" stroke="#122A5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
