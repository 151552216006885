//ToDo

// Django dashboard
// ***:8080/tui-cps/v1/destinations/

import PageHeader from "components/page-header/PageHeader";
import PropTypes from "prop-types";
import { tableColumns } from "../../util/destinations";
import {
  TableContextProvider,
  TableContextConsumer,
} from "context/TableContext";
import FilterBar from "components/filter-bar/FilterBar";
import Table from "components/table/Table";
import useDestinations from "../../hooks/useDestinations";
import {useContext} from "react";
import DestinationSelectionContext from "../../context/DestinationSelectionContext";

const DestinationsView = (props) => {
  const {
    setSelectedDestinations,
    selectedDestinationIds,
  } = useContext(DestinationSelectionContext);

  const { showLoading } = useDestinations();
  const onClickRow = (event, id) => {
    event.stopPropagation();
    setSelectedDestinations([id]);
    props.onNavigate();
  };

  /* const { enableDestinations } = useEnableDestinations() */


  /* todo - rename trips-list */
  return (
    <div>
      <div className="trips-list">
        <div className="mobi-tui-pagewrap" >
          <PageHeader title="Destinations" subtitle="">
            {
            }
          </PageHeader>

          <TableContextConsumer>
            <FilterBar withSearch={true} />
            <Table
              columns={tableColumns}
              showActions={false}
              enableSelect={false}
              onDetailClick={onClickRow}
              onSelectionChange={setSelectedDestinations}
              loading={showLoading}
              selectionModel={selectedDestinationIds}
            />
          </TableContextConsumer>
        </div>
      </div>
    </div>
  );
};

DestinationsView.propTypes = {
  showNotif: PropTypes.bool,
};
const DestinationsViewWrapped = (props) => {
  const { data } = useDestinations();
  return (
    <TableContextProvider data={data}>
      <DestinationsView {...props} />
    </TableContextProvider>
  );
};

export { DestinationsViewWrapped as DestinationsView };
