import {useHeaderFilters} from './useHeaderFilters'
import {useApi} from './api'
import { makeDateForAPI } from './useHeaderFilters';
import { useEffect, useState } from 'react';
import { singletonHook } from "react-singleton-hook";
const defaultInstance={
    id:'no_instance'
}
function useInstanceImpl(){
    const {get} = useApi()
    const {selectedDestination, selectedDate} = useHeaderFilters()
    const [instance, setInstance] = useState({})
    useEffect(async () => {
        try {
            const instanceData = await get('instances', {
                date: makeDateForAPI(selectedDate),
                destination: selectedDestination
            })
            setInstance(instanceData[0] ?? {})
        } catch (e){
            console.error(e)
        }
    },[selectedDestination, selectedDate])
    return instance
}
export const useInstance = singletonHook(defaultInstance, useInstanceImpl);
export default useInstance