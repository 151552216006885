import SettingsIcon from "components/icons/SettingsIcon";
import { PropTypes } from "prop-types";
import ListIcon, {
  BabyIconTransparent,
  BookingIcon,
} from "components/icons/Icon";
import Card from "components/card/Card";
import ListItem from "components/card/card-content/list-item/ListItem";
import "./TripDetailSidebar.css";
import StatusLabel from "components/card/card-content/StatusLabel";
import { useForm, Controller } from "react-hook-form";
import { useEditTripRemarks } from "hooks/useEditRemarks";
import ListItemInfo from "components/card/card-content/list-item/ListItemInfo";
import TextInput from "components/text-input/TextInput";
import { SnackbarContext } from "context/SnackbarContext";
import { useContext, useEffect } from "react";
import { TripSelectionContext } from "context/TripSelectionContext";
import useTripById from "hooks/useTripById";
import InfeasibilityReasons from "./InfeasibilityReasons";

TripDetailContent.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.string,
  numTasks: PropTypes.number,
  busCode: PropTypes.string,
  vehicleType: PropTypes.string,
  busRemarks: PropTypes.string,
  infant: PropTypes.bool,
  onClickEdit: PropTypes.node,
  onClickPassengers: PropTypes.func,
  onClickTasks: PropTypes.func,
};
export default function TripDetailContent(props) {
  const { openSnackbar } = useContext(SnackbarContext);
  const { selectedTripId } = useContext(TripSelectionContext);
  const { updateRemarks, isLoading: submitLoading } = useEditTripRemarks();
  const { data: { remarks = "" } = { remarks: "" } } =
    useTripById(selectedTripId);
  const { handleSubmit, reset, control } = useForm({
    defaultValues: { remarks },
  });
  const onSubmitRemarks = async ({ remarks }) => {
    try {
      await updateRemarks({ remarks, tripId: selectedTripId });
      openSnackbar({ message: "done", severity: "success" });
      reset({ remarks }, { keepDefaultValues: false, keepValues: false });
    } catch (e) {
      openSnackbar({ message: "error submitting remarks", severity: "error" });
    }
  };
  useEffect(() => {
    reset({ remarks }, { keepDefaultValues: false, keepValues: false });
  }, [remarks]);
  return (
    <div className="sidebar_trip-detail">
      <SettingsIcon />
      <div className="info" role="region" aria-label="Trip info">
        {props.icon ? props.icon : <ListIcon />}
        <div className="t-title xxs label">{props.label}</div>
        <div className="t-title sm title">{props.title}</div>
        <div className="info-tags">
          <StatusLabel text={props.status} />
          {props.infant ? (
            <div className="list-status infants-status t-title xxs ">
              <BabyIconTransparent />
              <span>Has Infants</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="cards-passengers-tasks">
        <Card>
          <ListItem
            onClick={props.onClickPassengers}
            icon={<BookingIcon />}
            title="View All Bookings"
            showArrow={true}
            showStatus={false}
            showSettingsIcon={false}
            selected={false}
          >
            <ListItemInfo label={`${props.passengers} Seats Occupied`} />
          </ListItem>
        </Card>
        <Card>
          <ListItem
            icon={<BookingIcon />}
            onClick={props.onClickTasks}
            title={"View By Stop"}
            showArrow={true}
            showStatus={false}
            showSettingsIcon={false}
            selected={false}
          >
            <ListItemInfo label={`${props.numTasks} Stops`} />
          </ListItem>
        </Card>
      </div>
      <div className="cards-bus">
        <Card>
          <ListItem
            icon={props.icon}
            showArrow={false}
            label={props.vehicleType}
            title={props.busCode}
          />
          <Controller
            control={control}
            name="remarks"
            defaultValue={remarks}
            key={selectedTripId}
            render={({ field: { value, onChange } }) => (
              <TextInput
                key={selectedTripId}
                onClickSubmit={handleSubmit(onSubmitRemarks)}
                onChange={onChange}
                name="remarks"
                value={value}
                defaultValue={remarks}
                submitLoading={submitLoading}
              />
            )}
          ></Controller>
          {
            props.infeasibility_reasons && props.infeasibility_reasons.length > 0 ? <InfeasibilityReasons reasons={props.infeasibility_reasons} /> : <></>
          } 
        </Card>
      </div>
      
    </div>
  );
}
