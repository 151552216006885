import React, { useRef } from "react";
import { SearchIcon } from "../icons/SearchIcon";
import styles from "./SearchBox.module.css";

// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const SearchBox = ({ placeholder = "placeholder", isSearching = false, onChange, ...props }) => {
  const initValue = "";
  const searchRef = useRef(initValue);

  const handleClear = (event) => {
    searchRef.current.value = initValue;
    event.target = searchRef.current;
    onChange(event);
  };
  const emptyInput = searchRef.current.value;
  const withSearch = emptyInput === initValue || isSearching;
  const btnClearStyle = withSearch ? styles["hidden"] : styles["clear-search-btn"];
  const searchWrapper = withSearch ? styles["search-wrapper"] : styles["selected"];

  return (
    <div className={searchWrapper}>
      <div className={styles["search-container"]}>
        <div className="search-icon">
          <SearchIcon className="pepe" />
        </div>
        <input
          className={styles["search-input"]}
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          ref={searchRef}
          {...props}
        />
        <div className={styles["btn-search"]}>
          <div className={styles["search-loader"]} hidden={!isSearching}>
            {/* <Loader type="Oval" color="#00BFFF" height={20} width={20} /> */}O{/*load library*/}
          </div>
          <button className={btnClearStyle} onClick={handleClear} hidden={emptyInput === initValue || isSearching}>
            X <span hidden>Clear Search</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
