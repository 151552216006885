import { createContext, useReducer } from "react";
import { peek } from "../util/util";

export const ViewsActions = {
  openView: "open",
  setCurrentView: "set_current",
  closeCurrentView: "close_current",
  closeAllViews: "close_all",
  emptyActionType: "",
};

export const ViewNames = {
  emptyViewName: "",
  tripDetail: "trip_detail",
  bookingDetail: "booking_detail",
  newTrip: "new_trip",
};

const emptyView = {
  viewName: ViewNames.emptyViewName,
  showSidebar: false,
  showPage: false,
};

function viewsReducer(viewsStack = [], action) {
  const { type = ViewsActions.emptyActionType, payload = {} } = action;
  if (peek(viewsStack) === payload) return viewsStack;

  if (type === ViewsActions.openView) {
    return [...viewsStack, payload];
  } else if (type === ViewsActions.setCurrentView) {
    const currentView = { ...viewsStack.pop(), ...payload };
    return [...viewsStack.slice(0, -1), currentView];
  } else if (type === ViewsActions.closeCurrentView) {
    return viewsStack.slice(0, -1);
  } else if (type === ViewsActions.closeAllViews) {
    return [];
  }
  return viewsStack;
}
export const ViewsContext = createContext({});
export function ViewsProvider(props) {
  const [views, dispatch] = useReducer(viewsReducer, []);
  const currentView = views[views.length - 1] ?? emptyView;
  const {
    viewName: currentViewName,
    showSidebar: sidebarCurrentlyShown,
    showPage: pageCurrentlyShown,
  } = currentView;
  const findView = (viewName) => {
    return views.find((view) => view.viewName === viewName);
  };
  const isVisible = (viewName) => {
    return !!findView(viewName);
  };
  const isVisibleSidebar = (viewName) => {
    return !!findView(viewName)?.showSidebar;
  };
  const isVisiblePage = (viewName) => {
    return !!findView(viewName)?.showPage;
  };
  const toggleShowPage = () =>
    dispatch({
      type: ViewsActions.setCurrentView,
      payload: { showPage: !pageCurrentlyShown },
    });

  const setShowPage = (showPage) => {
    dispatch({
      type: ViewsActions.setCurrentView,
      payload: { showPage },
    });
  };
  const openView = (view) => {
    dispatch({ type: ViewsActions.openView, payload: view });
  };

  const closeCurrentView = () =>
    dispatch({ type: ViewsActions.closeCurrentView });
  const closeAllViews = () => dispatch({ type: ViewsActions.closeAllViews });
  return (
    <ViewsContext.Provider
      value={{
        currentView,
        isVisible,
        isVisibleSidebar,
        isVisiblePage,
        toggleShowPage,
        setShowPage,
        currentViewName,
        sidebarCurrentlyShown,
        pageCurrentlyShown,
        openView,
        closeAllViews,
        closeCurrentView,
      }}
    >
      {props.children}
    </ViewsContext.Provider>
  );
}
