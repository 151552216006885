import { BookingSelectionContext } from "context/BookingSelectionContext"
import { ViewsContext } from "context/ViewsContext"
import { SnackbarContext } from "context/SnackbarContext"
import { TripSelectionContext } from "context/TripSelectionContext"

import { useContext, useEffect } from "react"
import { useQueryClient } from "react-query"
import { useForm, Controller } from "react-hook-form"
import useTripById from "hooks/useTripById"
import { useEditBookingRemarks } from "hooks/useEditRemarks"
import { useBookings } from "hooks/useBookings"
import useUnassignBookings from "hooks/useUnassignBookings"
import useAssignBookings from "hooks/useAssignBookings"
import useBookingById from "hooks/useBookingById"

import SidebarContentForMultipleBookings from "./SidebarContentForMultipleBookings"
import SidebarContentForSingleBooking from "./SidebarContentForSingleBooking"
import Card from "components/card/Card"
import TripCard from "./TripCard"
import TextInput from "components/text-input/TextInput"

export const BookingDetailSidebar = () => {
  const { isVisiblePage, toggleShowPage } = useContext(ViewsContext)
  const { openSnackbar } = useContext(SnackbarContext)

  const {
    selectedBookingIds,
    areMultipleBookingsSelected,
    setSelectedBookings,
  } = useContext(BookingSelectionContext)
  const firstBooking = selectedBookingIds[0]
  const {
    data: bookingDetailData = { passengers: [] },
    data: { trip: selectedTrip, remarks = "" } = {
      trip: undefined,
      remarks: "",
    },
    showLoading: sidebarIsLoading,
  } = useBookingById(firstBooking)

  const queryClient = useQueryClient()
  const { selectedTripId, setTripId } = useContext(TripSelectionContext)
  const { data: selectedTripBookings } = useBookings({ tripId: selectedTripId })
  const handleChangesToSelectedTrip = async () => {
    //  if you removed the only booking in the trip
    if (selectedTripBookings.length === 1) {
      setTripId(undefined)
      queryClient.removeQueries(["trip", selectedTripId])
      queryClient.removeQueries(["bookings", { tripId: selectedTripId }])
    } else {
      await queryClient.refetchQueries(["trip", selectedTripId])
      await queryClient.refetchQueries(["bookings", { tripId: selectedTripId }])
    }
  }
  const { unassignBookings, isLoading: removeInProgress } =
    useUnassignBookings()
  const { isLoading: assignInProgress } = useAssignBookings()
  const onClickRemoveTrip = async (bookingIds = selectedBookingIds) => {
    try {
      await unassignBookings(bookingIds)
      await handleChangesToSelectedTrip()
      setSelectedBookings([])
      openSnackbar({ message: "success", severity: "success" })
    } catch (e) {
      console.error(e)
      openSnackbar({ message: "error", severity: "error" })
    }
  }

  const { updateRemarks, isLoading: submitLoading } = useEditBookingRemarks()
  const { handleSubmit, reset, control } = useForm({
    mode: "onBlur",
    defaultValues: { remarks },
  })
  const onSubmitRemarks = ({ remarks }) => {
    try {
      updateRemarks({ bookingId: firstBooking, remarks })
      reset({ remarks })
      openSnackbar({ message: "saved", severity: "success" })
    } catch (e) {
      console.log(e)
      openSnackbar({ message: "error submitting remarks", severity: "error" })
    }
  }
  useEffect(() => {
    reset({ remarks }, { keepDefaultValues: false, keepValues: false })
  }, [remarks])

  const {
    data: selectedTripData,
    showLoading: tripIsLoading,
    isFetched: hasTripData,
  } = useTripById(selectedTrip)
  if (selectedBookingIds.length === 0) {
    return <></>
  }

  if (areMultipleBookingsSelected) {
    return (
      <SidebarContentForMultipleBookings
        numBookings={selectedBookingIds.length}
      />
    )
  } else if (!areMultipleBookingsSelected) {
    return (
      <>
        <SidebarContentForSingleBooking
          {...bookingDetailData}
          loading={sidebarIsLoading}
        />
        {!isVisiblePage("booking-detail") ? (
          <TripCard
            {...selectedTripData}
            selectedTrip={selectedTripData}
            loading={tripIsLoading || sidebarIsLoading}
            onClickReassignTrip={toggleShowPage}
            tripIsAssigned={!!bookingDetailData.trip}
            removeInProgress={removeInProgress}
            assignInProgress={assignInProgress}
            onClickRemoveTrip={onClickRemoveTrip}
            hasTripData={hasTripData}
          />
        ) : (
          <></>
        )}
        <Card loading={sidebarIsLoading}>
          <Controller
            control={control}
            name="remarks"
            defaultValue={remarks}
            key={firstBooking}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextInput
                key={firstBooking}
                onClickSubmit={handleSubmit(onSubmitRemarks)}
                onChange={onChange}
                value={value}
                name="remarks"
                defaultValue={remarks}
                submitLoading={submitLoading}
                onBlur={onBlur}
              />
            )}
          ></Controller>
        </Card>
      </>
    )
  }
}
export default BookingDetailSidebar
