import ListIcon from "../../../icons/Icon";
import { listItemShape } from "../../../../util/types";
import { PropTypes } from "prop-types";
import "./ListItem.css";
import "../CardContent.css";
import ListItemInfo from "./ListItemInfo";

ListItem.propTypes = {
  ...listItemShape,
  icon: PropTypes.node,
  showArrow: PropTypes.bool,
  selected: PropTypes.bool,
  content: PropTypes.node,
  actions: PropTypes.node,
  onClick: PropTypes.func,
  labelBottom: PropTypes.string,
  arrowDirection: PropTypes.string,
};
export default function ListItem(props) {
  const arrowDirection = props.arrowDirection ? props.arrowDirection : "right";
  const typeOfItem = props.showArrow ? "item witharrow " + arrowDirection : "item ";
  return (
    <div className={typeOfItem + ' card-content-horiz'} onClick={props.onClick}>
      {props.icon ? props.icon : <ListIcon />}
      <div className="card-info">
        <ListItemInfo title={props.title} label={props.label} />
        {props.children}
      </div>
      <div className="content">{props.content}</div>
      <div className="actions">
        {props.showSettingsIcon ? <div className="list-action edit"></div> : <></>}
        {props.actions}
      </div>
    </div>
  );
}
