import React, { createContext } from "react";
import useSearch from "../hooks/useSearch";
import useFilters from "../hooks/useFilters";
export const TableContext = createContext({ data: [] });
export function TableContextProvider({
  data = [],
  filterFields = [],
  initialFilters = {},
  ...props
}) {
  const {
    filters,
    filteredData,
    setFilters,
    numFilterResults,
    filterBarItems,
  } = useFilters(data, filterFields, initialFilters);
  const { searchResults, setSearchResults, requestSearch, cancelSearch } =
    useSearch(filteredData);
  return (
    <TableContext.Provider
      value={{
        searchResults,
        setSearchResults,
        requestSearch,
        cancelSearch,
        currentFilters: filters,
        rows: searchResults,
        onFilterChange: setFilters,
        rowsLength: numFilterResults,
        items: filterBarItems,
      }}
    >
      {props.children}
    </TableContext.Provider>
  );
}

export function TableContextConsumer(props) {
  return (
    <TableContext.Consumer>
      {(value) =>
        React.Children.map(props.children, (child) =>
          React.cloneElement(child, value)
        )
      }
    </TableContext.Consumer>
  );
}
