import { useContext } from "react";
import SidebarWithPage from "../composable/sidebar-with-page/SidebarWithPage";
import Alert from "../../components/alert/Alert";
import PageHeader from "components/page-header/PageHeader";
import HeaderButton from "components/page-header/HeaderButton";
import Loading from "../../components/loading/Loading";
import { BusIcon } from "components/icons/Icon";
import Card from "components/card/Card";
import ListItem from "components/card/card-content/list-item/ListItem";
import { BookingIcon } from "components/icons/Icon";
import CardContent from "../../components/card/card-content/CardContent";
import TuiInputLabel from "../../components/input-label/TuiInputLabel";
import "./NewTrip.css";
import RadioGroup from "../../components/card/card-content/radio-group/RadioGroup";
import useCreateTrip from "../../hooks/useCreateTrip";
import { ViewNames, ViewsContext } from "../../context/ViewsContext";
import { bookingsFilterFields } from "util/bookings";
import Table from "components/table/Table";
import FilterBar from "components/filter-bar/FilterBar";
import {
  TableContextProvider,
  TableContextConsumer,
} from "context/TableContext";
import { useBookings } from "hooks/useBookings";
import { Controller, useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import useVehicles from "../../hooks/useVehicles";
import { Tooltip } from "@material-ui/core";
const newTripColumns = [
  {
    field: "booking",
    headerName: "Lead Name",
    flex: 18,
    renderCell: (params) => (
      <>
        {params.value.icon ?? <BookingIcon />}
        <div className="info">
          <div className="t-label">{params.value.label} PAX</div>
          <div>{params.value.title}</div>
        </div>
      </>
    ),
    sortComparator: (value1, value2) =>
      value1.title.localeCompare(value2.title),
  },
  {
    field: "extBooking",
    headerName: "Booking",
    flex: 10,
  },
  { field: "area", headerName: "Area", flex: 8 },
  { field: "hotel", headerName: "Hotel", flex: 8 },
  {
    field: "tO",
    headerName: "T.O.",
    flex: 8,
  },
  { field: "flightNumber", headerName: "Flight (IATA)" },
  {
    field: "flightBookingTime",
    headerName: "Time",
    flex: 7,
  },
];
const EMPTY_INPUT_DATA = {
  sign: "",
  remarks: "",
  vehicle: "",
  bookingIds: [],
};
const maxAllowedSign = 99;

function NewTrip({ openSnackbar, ...props }) {
  const { isVisible, closeCurrentView } = useContext(ViewsContext);
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
    control,
    watch,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: EMPTY_INPUT_DATA,
  });

  const { data: vehicleOptions } = useVehicles();

  const clearInputData = () => {
    reset(EMPTY_INPUT_DATA);
  };

  const { createTrip, isLoading: createTripLoading } = useCreateTrip();
  const createTripAndAssignBookings = async ({
    sign,
    remarks,
    vehicle,
    bookingIds,
  }) => {
    if (!isEmpty(errors)) return;

    try {
      const { tripId } = await createTrip({
        sign,
        remarks,
        vehicle,
        bookingIds,
      });
      openSnackbar({ message: "trip " + tripId + " created", severity: "success" });
    } catch (e) {
      openSnackbar({ message: "error creating trip", severity: "error" });
      console.error("Could not save trip", e);
      throw e;
    }
  };
  const onClickSubmit = async (data) => {
    await createTripAndAssignBookings(data);
    clearInputData();
    closeCurrentView();
  };
  const onCancel = (e) => {
    e.stopPropagation();
    clearInputData();
    closeCurrentView();
  };
  const { data: bookings = [], showLoading: bookingsLoading } = useBookings();
  const selectedBookingsCount = watch("bookingIds").length;
  const renderBookingsListIfViewIsOpen = () => {
    if (isVisible(ViewNames.newTrip)) {
      return (
        <TableContextProvider
          data={bookings}
          filterFields={bookingsFilterFields}
          initialFilters={{ assignedTripSign: ["Unassigned"] }}
        >
          <TableContextConsumer>
            <FilterBar withSearch={true} />
          </TableContextConsumer>
          <Controller
            control={control}
            name="bookingIds"
            rules={{
              required: {
                value: true,
                message: "Please select at least one booking",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TableContextConsumer>
                <Table
                  width={"100%"}
                  showActions={false}
                  enableSelect={true}
                  onSelectionChange={onChange}
                  selectionModel={value}
                  columns={newTripColumns}
                  loading={
                    bookingsLoading || createTripLoading
                  }
                />
              </TableContextConsumer>
            )}
          ></Controller>
        </TableContextProvider>
      );
    } else {
      return <Loading />;
    }
  };
  return (
    <div style={{ zIndex: 60 }}>
      <SidebarWithPage
        className="sidebar-with-page__new-trip"
        showSidebar={isVisible(ViewNames.newTrip)}
        showPage={isVisible(ViewNames.newTrip)}
        sidebarContent={
          <div className="new-trip-sidebar-content">
            <div className="info">
              <BusIcon />
              <div className="t-title" role="heading" aria-level="1">
                New Trip
              </div>
            </div>
            <Card>
              <ListItem
                icon={<BookingIcon />}
                title={selectedBookingsCount + " Bookings"}
              />
            </Card>
            <Card>
              <form>
                <CardContent>
                  <TuiInputLabel for="sign-input">Sign</TuiInputLabel>
                  <textarea
                    {...register("sign", {
                      required: "Please enter a sign",
                      validate: (val) =>
                        !isNaN(val) || "Please enter a number from 0 - 99",
                      max: {
                        value: maxAllowedSign,
                        message: "Please enter a number from 0 - 99",
                      },
                      min: {
                        value: 0,
                        message: "Please enter a number from 0 - 99",
                      },
                    })}
                    defaultValue={null}
                    className="t-textarea h_one-line w_full bg_blue"
                    id="sign-input"
                  />
                  <Alert className="text-only" show={!!errors.sign}>
                    {errors?.sign?.message}
                  </Alert>
                </CardContent>
                <CardContent>
                  <TuiInputLabel>Vehicle Type</TuiInputLabel>
                  <RadioGroup>
                    {vehicleOptions.map((v) => (
                      <label
                        key={v.id}
                        htmlFor={v.id}
                        className="t-input-label t-title xxs"
                      >
                        <input
                          {...register("vehicle", {
                            required: "Please select a vehicle",
                          })}
                          className="radio"
                          type="radio"
                          value={v.id}
                          id={v.id}
                          key={v.id}
                        />
                        {`${v.type} - ${v.seats ?? "N/A"} seats`}
                      </label>
                    ))}
                    <Alert className="text-only" show={!!errors.vehicle}>
                      {errors?.vehicle?.message}
                    </Alert>
                  </RadioGroup>
                </CardContent>
                <CardContent>
                  <TuiInputLabel for="remarks-input">Remarks</TuiInputLabel>
                  <textarea
                    {...register("remarks", { required: false })}
                    defaultValue=""
                    className="t-textarea h_multiline w_full bg_blue"
                  />
                </CardContent>
              </form>
            </Card>
          </div>
        }
        pageContent={
          <div>
            <PageHeader
              title="Assign Bookings"
              actions={
                <>
                  <HeaderButton
                    key="cancel"
                    variant="light fill"
                    text="Cancel"
                    onClick={onCancel}
                  />
                  <Tooltip
                    key="tooltip"
                    title={errors?.bookingIds?.message ?? ""}
                    open={!!errors.bookingIds && isSubmitted}
                    arrow
                  >
                    <div>
                      <HeaderButton
                        variant="default fill"
                        text="Create Trip"
                        onClick={handleSubmit(onClickSubmit)}
                        loading={createTripLoading}
                      />
                    </div>
                  </Tooltip>
                </>
              }
            />
            {renderBookingsListIfViewIsOpen()}
          </div>
        }
      />
    </div>
  );
}

export const NewTripContainer = (props) => <NewTrip {...props} />;

export default NewTripContainer;
