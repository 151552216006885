import { PropTypes } from 'prop-types';
import { BookingIcon } from 'components/icons/Icon';
SidebarContentForMultipleBookings.propTypes = {
    numBookings: PropTypes.number
}
export default function SidebarContentForMultipleBookings(props) {
    return <>
        <div className="info">
            {props.icon ? props.icon : <BookingIcon />}
            <div className="t-title xs label">
                Update Bookings
            </div>
            <div className="t-title sm title">{`${props.numBookings} Bookings`}</div>
        </div>
    </>
}