import { NotificationIcon } from 'components/icons/Icon';
import './HeaderNotifButton.css'
import { PropTypes } from 'prop-types';
import { ButtonBase, CircularProgress} from '@material-ui/core';
import Badge from 'components/badge/Badge';
HeaderNotifButton.props = {
    onClick: PropTypes.func,
    showBadge: PropTypes.bool,
    loading: PropTypes.bool,
    badgeCount: PropTypes.node
}
export default function HeaderNotifButton (props){
    return <div className='top-nav-notif-container'>
        <ButtonBase className= 'header-notif-button' onClick={props.onClick}>
            {props.loading ? 
            <CircularProgress size={20} color='inherit'/>
                :
            <Badge 
                hide={!props.showBadge}
                label= {props.badgeCount}
                color={props.color}
                >
                <NotificationIcon/>
            </Badge>
            }
        </ButtonBase>
    </div>
}